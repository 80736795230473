/** @format */

import * as Actions from "../actions/ActionTypes";
const AddressReducer = (
  state = {
    isLoading: false,
    error: undefined,
    data: undefined,
    message: undefined,
    status: undefined,
    addressValue: {
      address_name: "",
      line_1: "",
      line_2: "",
      state_long_name: "",
      state_short_name: "",
      city_long_name: "",
      pin_code: "",
      address_note: "",
      is_default_billing: true,
    },
    addressDetailGlobal: undefined,
    addressId: null,
    showAdditionalDetails: false,
    errorMessage: {
      line_1: "",
      line_2: "",
      city_long_name: "",
      state_long_name: "",
      pin_code: "",
      address_name: "",
      address_note: "",
    },
    billingAddressLoader: false,
    billingAddress: null,
    billingAddressError: null,
  },
  action
) => {
  switch (action.type) {
    case Actions.CREATE_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.CREATE_ADDRESS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.CREATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.data,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
      });
    case Actions.EDIT_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.EDIT_ADDRESS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.EDIT_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.data,
      });
    case Actions.UPDATE_ADDRESS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.UPDATE_ADDRESS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    case Actions.UPDATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.data,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
      });
    case Actions.GET_ADDRESS_VALUES: {
      let addressValue = null;
      if (action.value)
        addressValue = { ...state.addressValue, ...action.value };
      return Object.assign({}, state, {
        addressValue,
        error: undefined,
      });
    }
    case Actions.RESET_ADDRESS_ERROR:
      return Object.assign({}, state, {
        error: undefined,
      });
    case Actions.ADDRESS_DETAIL_GLOBAL:
      return Object.assign({}, state, {
        addressDetailGlobal: action.data,
      });
    case Actions.GET_ADDRESS_ID:
      return Object.assign({}, state, {
        isLoading: false,
        addressId: action.addressId,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
        error: undefined,
      });
    case Actions.GET_ADDRESS_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        message: action.addressData,
        error: undefined,
      });
    case Actions.DISCARD_ADDRESS_ID: {
      return Object.assign({}, state, {
        addressId: null,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
      });
    }
    case Actions.GET_ADDRESS_ID_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
        error: action.error,
      });
    case Actions.SHOW_ADDITIONALDETAILS:
      return Object.assign({}, state, {
        showAdditionalDetails: action.isShow,
      });
    case Actions.SET_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
      });
    case Actions.GET_BILLING_ADDRESS:
      return Object.assign({}, state, {
        billingAddressLoader: true,
        billingAddress: null,
        billingAddressError: null,
      });
    case Actions.GET_BILLING_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        billingAddressLoader: false,
        billingAddress: action.billingAddress,
        billingAddressError: null,
      });
    case Actions.GET_BILLING_ADDRESS_ERROR:
      return Object.assign({}, state, {
        billingAddressLoader: false,
        billingAddress: null,
        billingAddressError: action.error,
      });
    case Actions.CLEAR_ADDRESS_VALUE:
      return Object.assign({}, state, {
        addressValue: {
          address_name: "",
          line_1: "",
          line_2: "",
          state_long_name: "",
          state_short_name: "",
          city_long_name: "",
          pin_code: "",
          address_note: "",
          is_default_billing: true,
        },
        errorMessage: {
          line_1: "",
          city_long_name: "",
          state_long_name: "",
          pin_code: "",
        },
      });
    default:
      return state;
  }
};

export default AddressReducer;
