/** @format */

import moment from "moment";
// import stripe from "tipsi-stripe";
import {
  GET_USER_URL,
  media_url,
  REACT_APP_MEDIA_URL,
  user_image,
} from "./Constants";
import { fetchGet } from "./request";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import {
  getAddressValue,
  serviceActionError,
  setShowAdditionalDetails,
} from "../actions/AddressActions";

export const getUser = async (user_id) => {
  const response = await fetchGet(`${GET_USER_URL}?customerId=${user_id}`);
  localStorage.setItem("user_data", JSON.stringify(response));
};

export const getExpiryDateFormat = (value) => {
  return value
    .replace(/[^0-9]/g, "")
    .replace(/^([2-9])$/g, "0$1")
    .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
    .replace(/^0{1,}/g, "0")
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
};

export const capitalizeFirstLetter = (string) => {
  // Unit Test Written
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const checkExistingNotes = (showEditedOrder, notes) => {
  if (showEditedOrder) {
    return (
      (notes[notes.length - 1].customer_notes != null &&
        notes[notes.length - 1].customer_notes != "") ||
      (notes[notes.length - 1].internal_notes != null &&
        notes[notes.length - 1].internal_notes != "") ||
      (notes[notes.length - 1].partner_notes != null &&
        notes[notes.length - 1].partner_notes != "")
    );
  } else {
    return (
      (notes?.customer_notes != null && notes?.customer_notes != "") ||
      (notes?.internal_notes != null && notes?.internal_notes != "") ||
      (notes?.partner_notes != null && notes?.partner_notes != "")
    );
  }
};

export const checkIfImagesExists = (statusList) => {
  return (
    statusList?.customer_status_images !== null ||
    statusList?.internal_status_images !== null ||
    statusList?.partner_status_images !== null
  );
};

export const getFormattedAddress = (locationCoordinates, showCurrent) => {
  if (!showCurrent) {
    return `${locationCoordinates?.data?.line_1} ${
      locationCoordinates?.data?.city_short_name
    }, ${locationCoordinates?.data?.state_short_name} ${
      locationCoordinates?.data?.pin_code
        ? locationCoordinates?.data?.pin_code
        : ""
    }`;
  } else {
    return `${locationCoordinates?.currentLocation?.line_1} ${
      locationCoordinates?.currentLocation?.city_short_name
    }, ${locationCoordinates?.currentLocation?.state_short_name} ${
      locationCoordinates?.currentLocation?.pin_code
        ? locationCoordinates?.currentLocation?.pin_code
        : ""
    }`;
  }
};

export async function delay(value) {
  await new Promise((resolve) => setTimeout(resolve, value));
}

export const checkIfNotesExists = (statusList) => {
  return (
    statusList?.customer_notes ||
    statusList?.internal_notes ||
    statusList?.partner_notes
  );
};

export const getMessageTime = (time) => {
  return new Date(time).toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getProfilePicture = (profile) => {
  if (profile) {
    if (profile?.includes(media_url)) {
      return profile;
    } else {
      return `${REACT_APP_MEDIA_URL}${profile}`;
    }
  }
  return user_image;
};

export const deleteAddressUnnecessaryFields = (
  addressValue,
  setDetails,
  addressState
) => {
  if (addressValue.country_short_name) {
    delete addressValue.country_short_name;
  }

  if (addressValue.country_long_name) {
    delete addressValue.country_long_name;
  }

  if (addressValue.city_short_name) {
    delete addressValue.city_short_name;
  }

  if (addressValue.latitude) {
    delete addressValue.latitude;
  }

  if (addressValue.longitude) {
    delete addressValue.longitude;
  }

  if (addressValue.address) {
    delete addressValue.address;
  }

  delete addressValue.door_no;
  delete addressValue.is_temporary;
  delete addressValue.service_id;
  delete addressValue.static_map;
  delete addressValue.status;
  delete addressValue.created_at;
  delete addressValue.updated_at;
  delete addressValue.phone_number;
  delete addressValue.delivery_boy_id;
  delete addressValue.partner;
  delete addressValue.pick_up_radius;
  delete addressValue.is_service_address;
  delete addressValue.login_type;
  delete addressValue.working_days;
  delete addressValue.opening_hours;
  delete addressValue.closing_hours;
  delete addressValue.id;
  delete addressValue.deliveryBoyId;
  delete addressValue.services;
  delete addressValue.created_by_partner_id;

  if (!setDetails) addressValue.address_id = addressState.addressId;

  return addressValue;
};

export const setOrderDetails = (serviceDetails, addressDetails) => {
  const data = localStorage.getItem("order_details_service");
  if (!data) {
    localStorage.setItem(
      "order_details_service",
      JSON.stringify(serviceDetails)
    );
    localStorage.setItem(
      "order_details_address",
      JSON.stringify(addressDetails)
    );
  }
};
export const getStripeTokeValue = async (props, details) => {
  try {
    let options = {
      type: "Card",
      name: "",
      currency: "usd",
    };
    const response = await props.stripe.createToken(details, options);

    return response;
  } catch (e) {
    return { error: e.message };
  }
};

export const convertToLocaleTime = (timeStr) => {
  const [hours, minutes, seconds] = timeStr.split(":");
  const dateObj = new Date();

  // Set the UTC hours, minutes, and seconds to the current date
  dateObj.setUTCHours(hours);
  dateObj.setUTCMinutes(minutes);
  dateObj.setUTCSeconds(seconds);

  // Convert to local time
  const localTimeStr = dateObj.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return localTimeStr;
};

export const dateFormatter = (date) => {
  const dates = date?.substring(8, 10);
  const month = date?.substring(5, 7);
  const year = date?.substring(0, 4);
  return month + "/" + dates + "/" + year;
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

/**
 *
 * @param {string} dateTime The string that needs to be formatted
 * @param {string} format The format of the date and time.
 * @returns Formatted date in the specified format
 */

export const getLocalTimeZone = (dateTime, format) => {
  const gmtDateTime = moment.utc(dateTime, "YYYY-MM-DD HH:mm");

  return gmtDateTime.local().format(format);
};
export const formatPhoneNumber = (number) => {
  // Unit Test Written
  if (!number) return false;

  number = number.replace(/[^0-9]/g, "");

  const formatNumber = ("" + number).replace(/\D/g, "");
  const match = formatNumber.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return number;
};

/**
 *
 * @param {string} number a number
 * @returns append zero at the start of the given number
 */
export const appendZero = (number) => {
  // Unit Test Written
  if (!number) return null;
  return number.toString().padStart(3, "0");
};

/**
 *
 * @param {string} name Name of the user
 * @returns a string that contains initials of the given string
 */
export const getInitial = (name) => {
  // Unit Test Written
  return name
    .split(" ")
    .map((word, i) => (i < 2 ? word[0] : ""))
    .join("");
};

export const formatDate = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

export const changeAddressHelper = async (details, dispatch) => {
  let body = {
    line_1: "",
    pin_code: "",
    address: "",
    latitude: "",
    longitude: "",
    city_long_name: "",
    city_short_name: "",
    state_long_name: "",
    state_short_name: "",
    country_long_name: "",
    country_short_name: "",
  };
  await getGeocode({ address: details.description }).then((results) => {
    dispatch(getAddressValue({ address: results[0].formatted_address }));
    const { lat, lng } = getLatLng(results[0]);
    let door_no;
    body = {
      ...body,
      address: results[0].formatted_address,
      latitude: lat.toString(),
      longitude: lng.toString(),
    };
    results[0].address_components.map((value) => {
      if (
        value?.types?.indexOf("street_number") > -1 ||
        value?.types?.indexOf("premise") > -1
      ) {
        door_no = value?.long_name;
      } else if (value.types.indexOf("route") > -1) {
        body = {
          ...body,
          line_1: `${door_no != undefined && door_no} ${value?.short_name}`,
        };
      } else if (
        value.types.indexOf("sublocality_level_1") > -1 ||
        value.types.indexOf("locality") > -1
      ) {
        body = {
          ...body,
          city_long_name: value.long_name,
          city_short_name: value.short_name,
        };
      } else if (value.types.indexOf("administrative_area_level_1") > -1) {
        body = {
          ...body,
          state_short_name: value.short_name,
          state_long_name: value.long_name,
        };
      } else if (value.types.indexOf("postal_code") > -1) {
        body = { ...body, pin_code: value.long_name };
      } else if (value.types.indexOf("country") > -1) {
        body = {
          ...body,
          country_long_name: value.long_name,
          country_short_name: value.short_name,
        };
      }
    });
  });

  const checkValues = await checkValuesHelper(body);

  if (checkValues.indexOf(false) > -1)
    return dispatch(serviceActionError("Please enter full address"));

  dispatch(getAddressValue({ ...body }));
  dispatch(setShowAdditionalDetails(true));
  return body;
};

const checkValuesHelper = async (body) => {
  return Object.keys(body).map((key) => {
    if (key !== "door_no") {
      return body[key] !== "";
    }
    return true;
  });
};

export const currentLocationHelper = (responseJson, position) => {
  const nameArr = responseJson?.results[0]?.formatted_address.split(",");
  const state = responseJson.results[0].address_components.filter(
    (x) => x.types.filter((t) => t == "administrative_area_level_1").length > 0
  )[0];
  const postalCode = responseJson.results[0].address_components.filter(
    (x) => x.types.filter((t) => t == "postal_code").length > 0
  )[0]?.short_name;
  const country = responseJson.results[0].address_components.filter(
    (x) => x.types.filter((t) => t == "country").length > 0
  )[0];
  const city = responseJson.results[0].address_components.filter(
    (x) => x.types.filter((t) => t == "locality").length > 0
  )[0];
  let body = {
    line_1: nameArr[0],
    pin_code: postalCode || undefined,
    address: responseJson?.results[0]?.formatted_address,
    latitude: position?.coords?.latitude.toString(),
    longitude: position?.coords?.longitude.toString(),
    city_long_name: city?.long_name,
    city_short_name: city?.short_name,
    state_long_name: state?.long_name,
    state_short_name: state?.short_name.substring(0, 2),
    country_long_name: country?.long_name,
    country_short_name: country?.short_name,
    address_name: "",
    address_note: "",
  };
  return body;
};
