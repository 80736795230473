/** @format */

// import strings from "../languages/strings.js";

export const API = process.env.REACT_APP_BASE_URL;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const RECAPTCHA_CONSTANT = "6LdoMLUeAAAAAIur2-zMyuDPxYuf3io256ut-HJR";
export const LOGIN_URL = "customer/signin";
export const SIGNUP_URL = "customer/signup";
export const GET_USER_URL = "customer/getCustomerById";
export const UPDATE_CUSTOMER = "customer/updateCustomer";
export const UPDATE_CUSTOMER_SETTINGS = "customer/updateCustomerSetting";
export const GET_SIGNED_URL = "customer/getSignedURL";
export const UPLOAD_PROFILE_IMAGE = "customer/uploadProfileImage";
export const RESET_PASSWORD_URL = "customer/resetPassword";
export const FORGOT_PASSWORD_URL = "customer/reset-password";
export const VERIFY_EMAIL = "customer/verify-email";
export const SOCIAL_MEDIA_LOGIN_URL = "customer/socialMediaLogin";
export const SOCIAL_MEDIA_SIGNUP_URL = "/customer/socialMediaSignup";
export const GET_SERVICES_URL = "service/getService";
export const GET_CATEGORY_BY_SERVICE_ID_URL = "category/getCategoryByServiceId";
export const GET_PRODUCT_BY_CATEGORY_ID = "product/getProductByCategoryId";
export const GET_ADD_ON_PRODUCT = "product/getAddOnProduct";
export const GET_SERVICE_ADDRESS = "address/getServiceAddress";
export const GET_CUSTOMER_ADDRESS = "address/getCustomerAddress";
export const GET_TEMPORARY_ADDRESS_URL = "customer/getTemporaryAddress";
export const GET_NEAR_BY_SERVICE_ADDRESS = "address/getNearByPickupAddress";
export const CREATE_ADDRESS_URL = "address/addAddress";
export const GET_CUSTOMER_ADDRESS_BY_ID_URL = "address/getCustomerAddressById";
export const UPDATE_ADDRESS_URL = "address/updateAddress";
export const DELETE_ADDRESS = "address/deleteAddress";
export const CREATE_ADD_TO_CART_URL = "cart/create";
export const DELETE_ADD_TO_CART_URL = "cart/deleteCart";
export const UPDATE_CART_ADDRESS = "cart/updateAddress";
export const GET_ADD_TO_CART_URL = "cart/getCart";
export const GET_CART_TOTAL_AMOUNT = "cart/getCartTotalAmount";
export const GET_APP_CONFIG_URL = "config/getAppConfig";
export const GET_PAYMENT_METHODS_URL = "payment/getPaymentMethods";
export const GET_ADD_ON_SERVICE_URL = "service/getAddOnService";
export const CHECK_PROMO_CODE = "deals/checkPromoCode";
export const GET_BANNER_IMAGE_URL = "config/getBannerImages";
export const GET_FORM_COPY_URL = "config/getFormCopy";
export const GET_FORM_COPY_BY_NAME_URL = "config/getFormCopyByName";
export const GET_GEOCODE_LOCATION = "config/geocode";
export const GET_DEALS_URL = "deals/getDeals";
export const CREATE_ORDER_URL = "order/create";
export const GET_ORDER_ITEMS_URL = "order/getOrderItems";
export const GET_ORDER_URL = "order/getOrder";
export const GET_ORDER_STATUS = "order/getOrderStatus";
export const ORDER_COUNT_URL = "order/getCustomersOrderCount";
export const STRIPE_PAYMENT_URL = "payment/stripePayment";
export const STRIPE_RECAPTCHA_URL = "payment/recaptcha";
export const GET_WALLET_AMOUNT_URL = "wallet/getCustomerWallet";
export const WALLET_PAYMENT_URL = "wallet/walletPayment";
export const ADD_WALLET_URL = "wallet/addWallet";
export const ADD_CARD_WITH_TOKEN = "wallet/addCardWithToken";
export const GET_CARD_URL = "wallet/getCard";
export const ADD_CARD_URL = "wallet/addCard";
export const EDIT_CARD_URL = "wallet/editCard";
export const DELETE_CARD_URL = "wallet/deleteCard";
export const GET_BILLING_ADDRESS_URL = "address/getBillingAddress";
export const CREATE_SERVICE_AREA_REQUEST_URL = "requestServiceArea/create";
export const GET_FAQ_URL = "config/getFAQ";
export const SUBMIT_FEATURE_REQUEST_URL = "config/submitFeatureRequest";
export const GET_SERVICE_AREA_URL = "config/getServiceArea";
export const ADD_BE_PARTNER_URL = "config/becomePartner";
export const CUSTOMER_DELETE = "customer/deleteRequest";
export const GET_PRIVACY_POLICY = "config/getPrivacyPolicy";
export const GET_TERMS_CONDITIONS = "config/getTermsConditions";
export const CUSTOMER_FORGOT_PASSWORD = "customer/forgotPassword";
export const CUSTOMER_CHECK_OTP = "customer/checkOTP";
export const SHARE_ORDER = "customer/shareOrder";
export const GET_NOTIFICATIONS = "notification/getNotifications";
export const UPDATE_NOTIFICATION_COUNT = "notification/updateNotificationCount";
export const UNSEEN_NOTIFICATION_COUNT = "notification/getNotificationCount";
export const GET_MESSAGE_BY_ORDER_ID = "chat/getMessagesByOrderId";
export const LOGOUT = "customer/signOut";
export const CLEAR_NOTIFICATIONS = "notification/clearNotifications";
export const GET_INVOICE = "order/getOrderInvoice";
export const UPDATE_ORDER_STATUS = "order/payInPerson";
export const CONTACT_US = "config/contactUs";

export const drop_off = "Select Service Provider";
export const pick_up = "Select Pick Up Location";
export const no_drop_off_msg =
  "Sorry, we do not have a $99DPF partner servicing your location yet. We are constantly adding more partners and service areas";
export const request_service = "Request Service Area";
export const support_text =
  "Let us know what you think, be sure to include as much details as possible so that we can get back to you quickly";

export const GET_ORDER_BY_ID = "order/getOrderDetail";

export const IMAGE_URL = process.env.REACT_APP_MEDIA_URL;

export const REACT_APP_GOOGLE_KEY_ENV = process.env.REACT_APP_GOOGLE_KEY_ENV;
export const REACT_APP_GEOCODE_KEY_ENV = process.env.REACT_APP_GEOCODE_KEY_ENV;

export const settings = "app_setting";
export const service = "service";
export const faq = "faq";
export const privacy = "privacy_policy";
export const product = "product";
export const register = "customer";
export const login = "customer/login";
export const address = "address";
export const address_list = "address/all";
export const address_delete = "address/delete";
export const my_orders = "get_orders";
export const promo_code = "promo";
export const profile = "customer";
export const profile_picture = "customer/profile_picture";
export const forgot = "customer/forgot_password";
export const reset = "customer/reset_password";
export const place_order = "order";
export const payment_list = "payment";
export const get_region = "get_region";
export const get_area = "get_area";
export const stripe_payment = "stripe_payment";
export const get_wallet = "customer/wallet";
export const add_wallet = "customer/add_wallet";
export const get_time = "get_time";
export const get_delivery_charge = "get_delivery_charge";
export const get_labels = "get_labels";
export const check_order_count = "check_order_count";
export const no_wallet = "Wallet balance is empty";
export const no_data = "No data found";
export const no_record = "No record found";
export const share_order = "Share our order";
export const complete_form =
  "Complete the form below to share a PDF of this order's current status and details";
export const add_location = "Add location";

export const original_order = "Show Original Order";

export const edited_order = "Show Edited Order";

export const total_nontaxable = "Total Nontaxable";
export const total_taxable = "Total Taxable";
export const adjustment = "Adjustment";

export const tabValues = ["0", "1", "2"];

//Size
export const screenHeight = "100%";
export const height_45 = Math.round((45 / 100) * screenHeight);
export const height_40 = Math.round((40 / 100) * screenHeight);
export const height_50 = Math.round((50 / 100) * screenHeight);
export const height_60 = Math.round((60 / 100) * screenHeight);
export const height_35 = Math.round((35 / 100) * screenHeight);
export const height_25 = Math.round((20 / 100) * screenHeight);
export const height_30 = Math.round((30 / 100) * screenHeight);
export const height_26 = Math.round((26 / 100) * screenHeight);

//Path
export const logo = require(".././assets/img/logo_with_name.png");
export const gLogo = require(".././assets/img/Google__G__Logo.png");
export const fbLogo = require(".././assets/img/facebookLogo.png");
export const appleLogo = require(".././assets/img/appleLogo.png");
export const forgot_password = require(".././assets/img/forgot_password.png");
export const reset_password = require(".././assets/img/reset_password.png");
export const loading = require(".././assets/img/loading.png");
export const pin = require(".././assets/img/location_pin.png");
export const login_image = require(".././assets/img/logo_with_name.png");
export const washing_machine = require(".././assets/img/washing-machine.png");
export const completed_icon = require(".././assets/img/completed.png");
export const active_icon = require(".././assets/img/active.png");
export const plus = require(".././assets/img/plus.png");
export const minus = require(".././assets/img/minus.png");
export const pickup = require(".././assets/img/pickup.png");
export const delivery = require(".././assets/img/delivery.png");
export const low_wallet = require(".././assets/img/wallet.png");
export const user_image = require("../assets/img/user.png");
export const logo99 = require("../assets/img/applogo.png");
export const smallLogo99 = require("../assets/img/99Logo.png");
export const emptyCart = require("../assets/img/cart.jpg");
export const cartIcon = require("../assets/img/wrenchalt.png");
export const acumediaA = require("../assets/img/Acumedia_A_Gray.png");
export const introSlider1 = require("../assets/img/introSlider1.png");
export const introSlider2 = require("../assets/img/introSlider2.png");
export const introSlider3 = require("../assets/img/introSlider3.png");
export const introSlider4 = require("../assets/img/introSlider4.png");

//Font Family
export const normal = "400";
export const semiBold = "600";
export const bold = "700";

//Map
export const AUTOCOMPLETE_API_URL =
  "https://maps.googleapis.com/maps/api/place/autocomplete/json?";
export const PLACE_API_URL =
  "https://maps.googleapis.com/maps/api/place/details/json?";
export const GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY_ENV;
export const LATITUDE_DELTA = 0.015;
export const LONGITUDE_DELTA = 0.0152;

export const REACT_APP_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

// acumedia url
export const ACUMEDIA_URL = "https://www.acumedia.com/";
export const ACUMEDIA_99DPF_URL = "https://www.99dpf.com";
// aws
export const ACCESS_KEY_ID = process.env.ACCESS_KEY_ID_ENV;
export const SECRET_ACCESS_KEY = process.env.SECRET_ACCESS_KEY_ENV;
export const S3_BUCKET = process.env.S3_BUCKET_ENV;
export const S3_REGION = process.env.S3_REGION_ENV;

// stripe
export const PUBLISHABLE = process.env.REACT_APP_PUBLISHABLE_ENV;

// zoho
export const ANDROID_SALESIQ_APP_KEY = process.env.ANDROID_SALESIQ_APP_KEY_ENV;
export const ANDROID_SALESIQ_ACCESS_KEY =
  process.env.ANDROID_SALESIQ_ACCESS_KEY_ENV;
export const IOS_SALESIQ_APP_KEY = process.env.IOS_SALESIQ_APP_KEY_ENV;
export const IOS_SALESIQ_ACCESS_KEY = process.env.IOS_SALESIQ_ACCESS_KEY_ENV;

// web client id
export const WEB_CLIENT_ID = process.env.WEB_CLIENT_ID_ENV;

export const CHECKSUM_KEY = process.env.CHECKSUM_KEY_ENV;
export const APPLE_TEXT = "apple";
export const GOOGLE_TEXT = "google";
export const FACEBOOK_TEXT = "facebook";

// menu item
export const menuItem = [
  // {name: 'My Profile', icon: 'account', navigateTo: 'Profile'},
  { name: "My Addresses", icon: "place", navigateTo: "Address" },
  { name: "Payment Methods", icon: "payments", navigateTo: "PaymentMethod" },
  { name: "Service Areas", icon: "map", navigateTo: "ServiceAreas" },
  { name: "Feature Request", icon: "forum", navigateTo: "FeatureRequest" },
  {
    name: "Become a Partner",
    icon: "people",
    navigateTo: "BecomePartner",
  },
  // {name: 'Account Delete', icon: 'account-remove', navigateTo: 'AccountDelete'},
  { name: "FAQ", icon: "help", navigateTo: "Faq" },
  { name: "Support", icon: "live-help", navigateTo: "" },
  { name: "Settings", icon: "settings", navigateTo: "Settings" },
];

export const pageList = [
  { name: "Notifications", icon: "chevron-right", navigateTo: "Notification" },
  {
    name: "Privacy Policy",
    icon: "chevron-right",
    navigateTo: "PrivacyPolicy",
  },
];

export const languageItem = [
  {
    name: "English",
  },
  {
    name: "Spanish",
  },
];

export const paymentExtraDetails = [
  { icon: "cash-multiple", color: "#3a9215" },
  { icon: "credit-card", color: "#c90000" },
  { icon: "wallet", color: "#000000" },
];

export const STATE_LIST = [
  {
    id: "Alabama",
    title: "Alabama",
    state_long_name: "Alabama",
    state_short_name: "AL",
  },
  {
    id: "Alaska",
    title: "Alaska",
    state_long_name: "Alaska",
    state_short_name: "AK",
  },
  {
    id: "American Samoa",
    title: "American Samoa",
    state_long_name: "American Samoa",
    state_short_name: "AS",
  },
  {
    id: "Arizona",
    title: "Arizona",
    state_long_name: "Arizona",
    state_short_name: "AZ",
  },
  {
    id: "Arkansas",
    title: "Arkansas",
    state_long_name: "Arkansas",
    state_short_name: "AR",
  },
  {
    id: "California",
    title: "California",
    state_long_name: "California",
    state_short_name: "CA",
  },
  {
    id: "Colorado",
    title: "Colorado",
    state_long_name: "Colorado",
    state_short_name: "CO",
  },
  {
    id: "Connecticut",
    title: "Connecticut",
    state_long_name: "Connecticut",
    state_short_name: "CT",
  },
  {
    id: "Delaware",
    title: "Delaware",
    state_long_name: "Delaware",
    state_short_name: "DE",
  },
  {
    id: "District Of Columbia",
    title: "District Of Columbia",
    state_long_name: "District Of Columbia",
    state_short_name: "DC",
  },
  {
    id: "Federated States Of Micronesia",
    title: "Federated States Of Micronesia",
    state_long_name: "Federated States Of Micronesia",
    state_short_name: "FM",
  },
  {
    id: "Florida",
    title: "Florida",
    state_long_name: "Florida",
    state_short_name: "FL",
  },
  {
    id: "Georgia",
    title: "Georgia",
    state_long_name: "Georgia",
    state_short_name: "GA",
  },
  {
    id: "Guam",
    title: "Guam",
    state_long_name: "Guam",
    state_short_name: "GU",
  },
  {
    id: "Hawaii",
    title: "Hawaii",
    state_long_name: "Hawaii",
    state_short_name: "HI",
  },
  {
    id: "Idaho",
    title: "Idaho",
    state_long_name: "Idaho",
    state_short_name: "ID",
  },
  {
    id: "Illinois",
    title: "Illinois",
    state_long_name: "Illinois",
    state_short_name: "IL",
  },
  {
    id: "Indiana",
    title: "Indiana",
    state_long_name: "Indiana",
    state_short_name: "IN",
  },
  {
    id: "Iowa",
    title: "Iowa",
    state_long_name: "Iowa",
    state_short_name: "IA",
  },
  {
    id: "Kansas",
    title: "Kansas",
    state_long_name: "Kansas",
    state_short_name: "KS",
  },
  {
    id: "Kentucky",
    title: "Kentucky",
    state_long_name: "Kentucky",
    state_short_name: "KY",
  },
  {
    id: "Louisiana",
    title: "Louisiana",
    state_long_name: "Louisiana",
    state_short_name: "LA",
  },
  {
    id: "Maine",
    title: "Maine",
    state_long_name: "Maine",
    state_short_name: "ME",
  },
  {
    id: "Marshall Islands",
    title: "Marshall Islands",
    state_long_name: "Marshall Islands",
    state_short_name: "MH",
  },
  {
    id: "Maryland",
    title: "Maryland",
    state_long_name: "Maryland",
    state_short_name: "MD",
  },
  {
    id: "Massachusetts",
    title: "Massachusetts",
    state_long_name: "Massachusetts",
    state_short_name: "MA",
  },
  {
    id: "Michigan",
    title: "Michigan",
    state_long_name: "Michigan",
    state_short_name: "MI",
  },
  {
    id: "Minnesota",
    title: "Minnesota",
    state_long_name: "Minnesota",
    state_short_name: "MN",
  },
  {
    id: "Mississippi",
    title: "Mississippi",
    state_long_name: "Mississippi",
    state_short_name: "MS",
  },
  {
    id: "Missouri",
    title: "Missouri",
    state_long_name: "Missouri",
    state_short_name: "MO",
  },
  {
    id: "Montana",
    title: "Montana",
    state_long_name: "Montana",
    state_short_name: "MT",
  },
  {
    id: "Nebraska",
    title: "Nebraska",
    state_long_name: "Nebraska",
    state_short_name: "NE",
  },
  {
    id: "Nevada",
    title: "Nevada",
    state_long_name: "Nevada",
    state_short_name: "NV",
  },
  {
    id: "New Hampshire",
    title: "New Hampshire",
    state_long_name: "New Hampshire",
    state_short_name: "NH",
  },
  {
    id: "New Jersey",
    title: "New Jersey",
    state_long_name: "New Jersey",
    state_short_name: "NJ",
  },
  {
    id: "New Mexico",
    title: "New Mexico",
    state_long_name: "New Mexico",
    state_short_name: "NM",
  },
  {
    id: "New York",
    title: "New York",
    state_long_name: "New York",
    state_short_name: "NY",
  },
  {
    id: "North Carolina",
    title: "North Carolina",
    state_long_name: "North Carolina",
    state_short_name: "NC",
  },
  {
    id: "North Dakota",
    title: "North Dakota",
    state_long_name: "North Dakota",
    state_short_name: "ND",
  },
  {
    id: "Northern Mariana Islands",
    title: "Northern Mariana Islands",
    state_long_name: "Northern Mariana Islands",
    state_short_name: "MP",
  },
  {
    id: "Ohio",
    title: "Ohio",
    state_long_name: "Ohio",
    state_short_name: "OH",
  },
  {
    id: "Oklahoma",
    title: "Oklahoma",
    state_long_name: "Oklahoma",
    state_short_name: "OK",
  },
  {
    id: "Oregon",
    title: "Oregon",
    state_long_name: "Oregon",
    state_short_name: "OR",
  },
  {
    id: "Palau",
    title: "Palau",
    state_long_name: "Palau",
    state_short_name: "PW",
  },
  {
    id: "Pennsylvania",
    title: "Pennsylvania",
    state_long_name: "Pennsylvania",
    state_short_name: "PA",
  },
  {
    id: "Puerto Rico",
    title: "Puerto Rico",
    state_long_name: "Puerto Rico",
    state_short_name: "PR",
  },
  {
    id: "Rhode Island",
    title: "Rhode Island",
    state_long_name: "Rhode Island",
    state_short_name: "RI",
  },
  {
    id: "South Carolina",
    title: "South Carolina",
    state_long_name: "South Carolina",
    state_short_name: "SC",
  },
  {
    id: "South Dakota",
    title: "South Dakota",
    state_long_name: "South Dakota",
    state_short_name: "SD",
  },
  {
    id: "Tennessee",
    title: "Tennessee",
    state_long_name: "Tennessee",
    state_short_name: "TN",
  },
  {
    id: "Texas",
    title: "Texas",
    state_long_name: "Texas",
    state_short_name: "TX",
  },
  {
    id: "Utah",
    title: "Utah",
    state_long_name: "Utah",
    state_short_name: "UT",
  },
  {
    id: "Vermont",
    title: "Vermont",
    state_long_name: "Vermont",
    state_short_name: "VT",
  },
  {
    id: "Virgin Islands",
    title: "Virgin Islands",
    state_long_name: "Virgin Islands",
    state_short_name: "VI",
  },
  {
    id: "Virginia",
    title: "Virginia",
    state_long_name: "Virginia",
    state_short_name: "VA",
  },
  {
    id: "Washington",
    title: "Washington",
    state_long_name: "Washington",
    state_short_name: "WA",
  },
  {
    id: "West Virginia",
    title: "West Virginia",
    state_long_name: "West Virginia",
    state_short_name: "WV",
  },
  {
    id: "Wisconsin",
    title: "Wisconsin",
    state_long_name: "Wisconsin",
    state_short_name: "WI",
  },
  {
    id: "Wyoming",
    title: "Wyoming",
    state_long_name: "Wyoming",
    state_short_name: "WY",
  },
];

export const CHECKOUT_ACTION = "CHECKOUT_ACTION";
export const BILLING_ACTION = "BILLING_ACTION";
export const CARD_ACTION = "CARD_ACTION";

export const PHONE_FIELD_LENGTH = 10;
export const FULL_NAME_MIN_LENGTH = 3;
export const FULL_NAME_MAX_LENGTH = 70;
export const COMPANY_NAME_MIN_LENGTH = 2;
export const COMPANY_NAME_MAX_LENGTH = 120;
export const EMAIL_MIN_LENGTH = 5;
export const EMAIL_MAX_LENGTH = 255;
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 128;
export const APARTMENT_MAX_LENGTH = 20;
export const CITY_MIN_LENGTH = 2;
export const CITY_MAX_LENGTH = 28;
export const STATE_MIN_LENGTH = 2;
export const STATE_MAX_LENGTH = 2;
export const FULL_STATE_MAX_LENGTH = 52;
export const POSTAL_CODE_MIN_LENGTH = 5;
export const POSTAL_CODE_MAX_LENGTH = 5;
export const PROMO_CODE_MIN_LENGTH = 5;
export const PROMO_CODE_MAX_LENGTH = 20;
export const CARD_NICKNAME_MAX_LENGTH = 20;
export const CARD_NUMBER_MIN_LENGTH = 15;
export const CARD_NUMBER_MAX_LENGTH = 16;
export const CVC_MIN_LENGTH = 3;
export const CVC_MAX_LENGTH = 4;

export const select_service = "select_service";
export const select_category = "select_category";
export const select_location = "select_location";
export const confirm_location = "confirm_location";
export const select_type = "select_type";
export const select_product = "select_product";
export const order_confirm = "order_confirm";

export const orders_pending = "pending orders";
export const payment_pending = "pending payment";
export const completed_orders = "completed orders";
export const total_orders = "Total Service Orders";

export const boxShadow = "0px 3px 6px #00000029";

export const EMAIL_IN_USE = "That email address is already in use!";

export const DIALOG_DESCRIPTION =
  "To continue to dashboard, Please Enter your Company name and Phone Number in the form below.";

export const UNABLE_CHAT =
  "Chat cannot be initiated for this order at the moment because the order is not assigned yet";

export const no_card = "No card found";

export const payment_method = "Payments Method";

export const sure_msg = "Are you sure you want to delete this card?";

export const sure_msg_location =
  "Are you sure you want to delete this location?";

export const add_payment_method = "Add Payment Method";

export const no_notifications = " No notification found at the moment";

export const media_url = "services-image-99dpf";

export const terms = "I accept Terms and Conditions";

export const checkout = "Checkout";

export const total = "Total";

export const ContactUs = "Or feel free to call us at";
export const payment_description =
  "You are electing to pay by cash/Check at the time of service.";

export const proceed = "To Proceed Further";

export const Forgot_Password_Error =
  "There is an error in resetting your password. Kindly reset it again.";

export const select_method = "Select a Payment Method";

export const paid_by = "Amount paid by Wallet";

export const elected_cod =
  "You have elected to pay by cash/Check at the time of service";

export const acumedia = "AcuMedia Corporation";

export const add_address = "Add New Address";

export const no_address = "No default Billing Address";

export const card_details = "Please Enter Card Details Properly";

export const change = "Change";

export const app_title = "$99DPF";

export const select_billing_address = "Please Select Billing Address";

export const Add_Additional_Services = "Add Additional Services";
export const profile_update = "Profile updated Successfully";

export const password_reset = "Password updated Successfully";

export const forgot_password_success =
  "Password Updated Successfully,Continue to login app.";

export const login_alert = "Login Successfully";

export const logout_alert = "Logout Successfully";

export const password_reset_fail = "Current Password does not match";

export const verify_email_success = "You have successfully verify your email.";

export const verify_email_fail = "Kindly verify your email again.";
