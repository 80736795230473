import * as Actions from "../actions/ActionTypes";
const ResetPasswordReducer = (
  state = {
    error: undefined,
    status: undefined,
    isLoading: false,
    resetPasswordForm: {
      new_password: "",
      confirm_password: "",
      current_password: "",
    },
    resetPasswordError: {
      new_password: "",
      confirm_password: "",
      current_password: "",
      api_error:"",
    },
  },
  action,
) => {
  switch (action.type) {
    case Actions.RESET_PASSWORD_VALUE:
      return Object.assign({}, state, {
        resetPasswordForm: action.formValue,
      });
    case Actions.RESET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        resetPasswordError: action.errorValue,
      });
    case Actions.RESET_PASSWORD_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case Actions.RESET_PASSWORD_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        resetPasswordForm: {
          new_password: "",
          confirm_password: "",
          current_password: "",
        },
      });
    case Actions.RESET_PASSWORD_REQUEST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
      });
    default:
      return state;
  }
};

export default ResetPasswordReducer;
