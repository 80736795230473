import * as ActionTypes from "./ActionTypes";

export const getPaymentMethod = () => ({
  type: ActionTypes.GET_PAYMENT_METHOD,
});

export const getPaymentMethodSuccess = paymentMethod => ({
  type: ActionTypes.GET_PAYMENT_METHOD_SUCCESS,
  paymentMethod,
});

export const getPaymentMethodError = error => ({
  type: ActionTypes.GET_PAYMENT_METHOD_ERROR,
  error,
});

export const setPaymentModalDetails = (modalDetails, methodNdx) => ({
  type: ActionTypes.SET_PAYMENT_MODAL_DETAILS,
  modalDetails,
  methodNdx,
});

export const togglePaymentModal = () => ({
  type: ActionTypes.TOGGLE_PAYMENT_MODAL,
});

export const getConfirmPayment = confirmPaymentMethod => ({
  type: ActionTypes.GET_CONFIRM_PAYMENT,
  confirmPaymentMethod,
});

export const submitOrder = () => ({
  type: ActionTypes.SUBMIT_ORDER,
});

export const submitOrderSuccess = () => ({
  type: ActionTypes.SUBMIT_ORDER_SUCCESS,
});

export const submitOrderError = () => ({
  type: ActionTypes.SUBMIT_ORDER_ERROR,
});

export const getCardDetails = cardDetails => ({
  type: ActionTypes.GET_CARD_DETAILS,
  cardDetails,
});

export const getStripeToken = stripeToken => ({
  type: ActionTypes.GET_STRIPE_TOKEN,
  stripeToken,
});

export const setCardErrorMessage = errorMessage => ({
  type: ActionTypes.SET_CARD_ERROR_MESSAGE,
  errorMessage,
});

export const getCustomerWalletAmount = () => ({
  type: ActionTypes.GET_CUSTOMER_WALLET_AMOUNT,
});

export const getCustomerWalletAmountSuccess = walletData => ({
  type: ActionTypes.GET_CUSTOMER_WALLET_AMOUNT_SUCCESS,
  walletData,
});
export const getCustomerWalletAmountError = () => ({
  type: ActionTypes.GET_CUSTOMER_WALLET_AMOUNT_ERROR,
});

export const getLastScreen = lastScreen => ({
  type: ActionTypes.GET_LAST_SCREEN,
  lastScreen,
});

export const noDefaultBillingAddress = isDefaultBillingAddress => ({
  type: ActionTypes.NO_DEFAULT_BILLING_ADDRESS,
  isDefaultBillingAddress,
});
