/** @format */

import { makeStyles } from "@mui/styles";
import { normal } from "../../config/Constants";
const localStyles = makeStyles((theme) => {
  return {
    container: {
      height: 30,
      width: "100%",
      background: "black",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      fontWeight: normal,
      boxShadow: "0px 3px 6px #00000029",
      position: "fixed",
      bottom: 0,
      zIndex: 5,
      paddingTop: 5,
      paddingBottom: 5,
      [theme.breakpoints.down("md")]: {
        paddingRight: "20px",
      },
    },
    containerSpan: {
      color: "white",
      marginLeft: 5,
      fontWeight: 400,
      fontSize: "14px",
      [theme.breakpoints.down("1370")]: {
        fontSize: "12px",
      },
    },
    copyrightIcon: {
      color: "white",
      fontSize: "20px",
      [theme.breakpoints.down("1370")]: {
        fontSize: "16px",
      },
    },
  };
});

export default localStyles;
