/** @format */

import React from "react";
import * as colors from "../assets/css/Colors";

import useStyles from "../GlobalStyle";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress } from "@mui/material";
import RoomIcon from "@mui/icons-material/Room";

export function Loader() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress
        color='inherit'
        sx={{ color: colors.theme_bg_red, marginTop: "10px" }}
      />
    </div>
  );
}

export function BackArrow(props) {
  const navigate = useNavigate();
  const GlobalStyles = useStyles();

  return (
    <div className={GlobalStyles.arrow} onClick={() => navigate(props.link)}>
      <ArrowBackIcon sx={{ color: colors.icon_color }} fontSize={"large"} />
    </div>
  );
}

export function CommonError(props) {
  if (props.type === "textError") {
    return (
      <span
        style={{
          textAlign: "center",
          fontSize: 12,
          color: colors.theme_bg_red,
          marginTop: 10,
        }}>
        {props.error}
      </span>
    );
  }
}

export const Marker = () => {
  return (
    <div>
      <RoomIcon sx={{ color: colors.icon_color }} />
    </div>
  );
};

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabPanel'
      hidden={value !== index}
      id={`tabPanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `tabPanel-${index}`,
  };
};
