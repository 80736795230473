import * as ActionTypes from "./ActionTypes";

export const forgotPasswordValue = (formValue) => ({
  type: ActionTypes.FORGOT_PASSWORD_VALUE,
  formValue,
});

export const forgotPasswordValueError = (errorValue) => ({
  type: ActionTypes.FORGOT_PASSWORD_ERROR,
  errorValue,
});

export const onSubmitForgotRequest = () => ({
  type: ActionTypes.FORGOT_PASSWORD_REQUEST,
});

export const onSubmitForgotRequestSuccess = (message) => ({
  type: ActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
  message,
});

export const onSubmitForgotRequestError = (error) => ({
  type: ActionTypes.FORGOT_PASSWORD_REQUEST_ERROR,
  error,
});
