/** @format */

import * as Actions from "../actions/ActionTypes";
const ProductReducer = (
  state = {
    cart_count: undefined,
    cart_items: [],
    isLoading: false,
    error: undefined,
    data: undefined,
    message: undefined,
    status: undefined,
    categoryList: [],
    tabPage: 0,
    categoryId: undefined,
    productList: [],
    addOnProductList: [],
    showButton: false,
    amount: 0,
    imageLoading: false,
    productCount: 0,
    categoryNdx: undefined,
    categoryNdxValue: 0,
  },
  action
) => {
  switch (action.type) {
    case Actions.PRODUCT_LIST_PENDING: {
      return Object.assign({}, state, {
        isLoading: true,
        categoryId: action.categoryId,
        productList: [],
        addOnProductList: [],
        error: undefined,
        categoryNdxValue: action.categoryNdxValue,
      });
    }
    case Actions.PRODUCT_LIST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        productList: [],
      });
    case Actions.PRODUCT_LIST_SUCCESS: {
      const addOnProductList = [];
      action.data.productList.map((productList) => {
        if (productList.addOnProduct && productList.addOnProduct.length > 0) {
          productList.addOnProduct.map((addOnProduct) => {
            addOnProductList.push(addOnProduct);
          });
        }
      });
      return Object.assign({}, state, {
        isLoading: false,
        productList: action.data,
        addOnProductList,
        error: undefined,
        productCount: action.data.productCount,
      });
    }
    case Actions.ADD_TO_CART:
      return Object.assign({}, state, {
        cart_items: action.data,
        cart_count: action.data.length,
        amount: action.amount,
      });
    case Actions.PRODUCT_RESET:
      return Object.assign({}, state, {
        cart_items: [],
        cart_count: undefined,
      });
    case Actions.PRODUCT_LIST_RESET:
      return Object.assign({}, state, {
        data: undefined,
      });
    case Actions.GET_CATEGORY_LIST:
      return Object.assign({}, state, {
        isLoading: true,
        categoryList: [],
      });
    case Actions.GET_CATEGORY_LIST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        categoryList: action.categoryList,
      });
    case Actions.GET_CATEGORY_LIST_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        categoryList: [],
        error: action.error,
      });
    case Actions.SELECTED_PRODUCT_LIST: {
      let checkIsProductSelected = null;
      if (action?.productList?.length > 0) {
        checkIsProductSelected = action.productList.find(
          (product) => product.isSelected
        );
      }
      return Object.assign({}, state, {
        productList: action.productList,
        showButton: !!checkIsProductSelected,
      });
    }
    case Actions.SELECTED_ADD_ON_PRODUCT_LIST: {
      return Object.assign({}, state, {
        addOnProductList: action.addOnProductList,
      });
    }
    case Actions.IS_PRODUCT_IMAGE_LOADING:
      return Object.assign({}, state, {
        imageLoading: action.imageLoading,
      });
    case Actions.GET_CATEGORY_ID:
      return Object.assign({}, state, {
        categoryId: action.categoryId,
      });
    case Actions.EMPTY_PRODUCT_LIST:
      return Object.assign({}, state, {
        productList: [],
        addOnProductList: [],
        categoryId: undefined,
      });
    case Actions.UPDATE_PRODUCT_SELECTION: {
      const index = state.productList.findIndex(
        (item) => item.product_id === action.productId
      );
      if (index > -1) {
        const productList = [...state.productList];
        productList[index] = { ...state.productList[index], isSelected: 0 };
        return Object.assign({}, state, {
          productList,
        });
      } else {
        const indicesWithNonEmptyAddOnProduct = state.productList.reduce(
          (indices, obj, index) => {
            if (obj.addOnProduct[0].product_id === action.productId) {
              indices.push(index);
              obj.addOnProduct[0]["isSelected"] = 0;
            }
            return indices;
          },
          []
        );

        if (indicesWithNonEmptyAddOnProduct) {
          const productList = [...state.productList];
          return Object.assign({}, state, {
            productList,
          });
        }

        return Object.assign({}, state, {
          productList: state.productList,
        });
      }
    }
    default:
      return state;
  }
};

export default ProductReducer;
