/** @format */

import { combineReducers } from "@reduxjs/toolkit";
import RegisterReducer from "./RegisterReducer";
import AuthReducer from "./AuthReducer";
import LoginReducer from "./LoginReducer";
import ForgotReducer from "./ForgotReducer";
import CommonReducer from "./CommonReducer";
import ProfileReducer from "./ProfileReducer";
import HomeReducer from "./HomeReducer";
import MyOrdersReducer from "./MyOrdersReducer";
import ProductReducer from "./ProductReducer";
import AddressListReducer from "./AddressListReducer";
import ServicesReducer from "./ServicesReducer";
import CartReducer from "./CartReducer";
import CheckoutReducer from "./CheckoutReducer";
import ResetPasswordReducer from "./ResetPasswordReducer";
import AddressReducer from "./AddressReducer";
import CardReducer from "./CardReducer";
import ContactReducer from "./ContactReducer";
import ForgotPasswordReducer from "./ForgotPasswordReducer";

const allReducers = combineReducers({
  auth: AuthReducer,
  login: LoginReducer,
  register: RegisterReducer,
  forgot: ForgotReducer,
  common: CommonReducer,
  profile: ProfileReducer,
  home: HomeReducer,
  myOrders: MyOrdersReducer,
  product: ProductReducer,
  address_list: AddressListReducer,
  services: ServicesReducer,
  cart: CartReducer,
  checkout: CheckoutReducer,
  resetPassword: ResetPasswordReducer,
  forgotPassword:ForgotPasswordReducer,
  address: AddressReducer,
  card_list: CardReducer,
  contact: ContactReducer,
});

export default allReducers;
