/** @format */

/* eslint-disable quotes */

import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { master_major, master_minor, master_patch } from "../../version";
import localStyles from "./localStyles";

const Footer = () => {
  const styles = localStyles();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className={styles.container}>
        <div
          style={{ display: "flex", alignItems: "center", paddingRight: 20 }}>
          <CopyrightIcon className={styles.copyrightIcon} />
          <span className={styles.containerSpan} data-testid='footer-text'>
            2018 - {currentYear} | All Rights Reserved | $99DPF is a FleetServ™
            Product v{master_major}.{master_minor}.{master_patch}
          </span>
        </div>
      </div>
    </>
  );
};

export default Footer;
