/** @format */

import * as ActionTypes from "./ActionTypes";

export const serviceActionPending = (offset) => ({
  type: ActionTypes.MYORDERS_LIST_PENDING,
  offset,
});

export const serviceActionError = (error) => ({
  type: ActionTypes.MYORDERS_LIST_ERROR,
  error: error,
});

export const serviceActionSuccess = (data) => ({
  type: ActionTypes.MYORDERS_LIST_SUCCESS,
  data: data,
});

export const filterType = (data) => ({
  type: ActionTypes.FILTER_TYPE,
  data: data,
});

export const getOrderId = (orderId) => ({
  type: ActionTypes.GET_ORDER_ID,
  orderId,
});

export const getOrderItems = () => ({
  type: ActionTypes.GET_ORDER_ITEMS,
});

export const getOrderItemsSuccess = (data) => ({
  type: ActionTypes.GET_ORDER_ITEMS_SUCCESS,
  data,
});

export const getOrderItemsError = (error) => ({
  type: ActionTypes.GET_ORDER_ITEMS_ERROR,
  error,
});

export const getOrderStatus = () => ({
  type: ActionTypes.GET_ORDER_STATUS,
});

export const getOrderStatusSuccess = (orderStatusList) => ({
  type: ActionTypes.GET_ORDER_STATUS_SUCCESS,
  orderStatusList,
});

export const getOrderStatusError = (error) => ({
  type: ActionTypes.GET_ORDER_STATUS_ERROR,
  error,
});

export const orderTabPage = (page) => ({
  type: ActionTypes.ORDER_TAB_PAGE,
  page,
});

export const getNotificationOrderId = (notificationOrderId) => ({
  type: ActionTypes.NOTIFICATION_ORDER_ID,
  notificationOrderId,
});

export const activeOrdersPending = (offset) => ({
  type: ActionTypes.ACTIVE_ORDERS_PENDING,
  offset,
});

export const activeOrdersError = (error) => ({
  type: ActionTypes.ACTIVE_ORDERS_ERROR,
  error: error,
});

export const activeOrdersSuccess = (data) => ({
  type: ActionTypes.ACTIVE_ORDERS_SUCCESS,
  data,
});

export const shareOrderPending = () => ({
  type: ActionTypes.SHARE_ORDER_PENDING,
});

export const shareOrderError = (error) => ({
  type: ActionTypes.SHARE_ORDER_ERROR,
  error: error,
});

export const shareOrderSuccess = (data) => ({
  type: ActionTypes.SHARE_ORDER_SUCCESS,
  data,
});

export const getOrderByIdPending = () => ({
  type: ActionTypes.GET_ORDER_BY_ID_PENDING,
});

export const getOrderByIdSuccess = (data) => ({
  type: ActionTypes.GET_ORDER_BY_ID_SUCCESS,
  data,
});

export const getOrderByIdError = (error) => ({
  type: ActionTypes.GET_ORDER_BY_ID_ERROR,
  error,
});

export const getOrderInvoiceSuccess = (data) => ({
  type: ActionTypes.GET_ORDER_INVOICE_SUCCESS,
  data,
});

export const getOrderInvoiceError = (error) => ({
  type: ActionTypes.GET_ORDER_INVOICE_ERROR,
  error,
});

export const changeOrderStatusPending = () => ({
  type: ActionTypes.CHANGE_ORDER_STATUS_PENDING,
});

export const changeOrderStatusError = (error) => ({
  type: ActionTypes.CHANGE_ORDER_STATUS_ERROR,
  error,
});

export const changeOrderStatusSuccess = (data) => ({
  type: ActionTypes.CHANGE_ORDER_STATUS_SUCCESS,
  data,
});

export const orderStatusChanged = (data) => ({
  type: ActionTypes.ORDER_STATUS_CHANGED,
  data,
});

export const newOrderCreated = (data) => ({
  type: ActionTypes.NEW_ORDER_CREATED,
  data,
});
