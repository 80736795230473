/** @format */

import React, { useState, useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "./config/history";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { getNewToken } from "./firebase";
import { fetchGet, fetchPost } from "./config/request";
import {
  GET_CART_TOTAL_AMOUNT,
  GET_USER_URL,
  SERVER_URL,
  UPDATE_CUSTOMER_SETTINGS,
} from "./config/Constants";
import { getUser } from "./actions/SplashActions";
import { checkIsAuthError, checkIsAuthSuccess } from "./actions/AuthAction";
import strings from "./languages/strings";
import Header from "./components/header/Header";
import { ToastContainer } from "react-toastify";
import {
  getCompletedOrders,
  getMyActiveOrders,
  getOrderCounts,
} from "./components/ordersHistory/services";
import { Loader } from "./components/GeneralComponents";
import { addToCart } from "./actions/ProductActions";
import { ThemeProvider } from "@mui/styles";
import { theme } from "./theme/theme";
import io from "socket.io-client";
import EmailVerification from "./views/EmailVerification";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Footer from "./components/footer/Footer";

function App() {
  const { isAuth } = useSelector((state) => state.auth);
  const fcm_token = localStorage.getItem("fcm_token");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const Login = React.lazy(() => import("./views/Login"));
  const Register = React.lazy(() => import("./views/Register"));
  const GetStarted = React.lazy(() => import("./views/GetStarted"));
  const Forgot = React.lazy(() => import("./views/Forgot"));
  const Dashboard = React.lazy(() => import("./views/Dashboard"));
  const Orders = React.lazy(() => import("./views/Orders"));
  const NewOrder = React.lazy(() => import("./views/NewOrder"));
  const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));
  const Checkout = React.lazy(() => import("./components/checkout/Checkout"));

  useEffect(() => {
    getNewToken();
    home();
    getAmount();
  }, []);

  useEffect(() => {
    if (isAuth) {
      getMyActiveOrders(dispatch, 0, 100);
      getCompletedOrders(dispatch, true, 0, 100);
      getOrderCounts(dispatch);
    }
  }, [isAuth]);

  const getAmount = async () => {
    const cartAmount = await fetchGet(
      `${GET_CART_TOTAL_AMOUNT}?customer_id=${
        JSON.parse(localStorage.getItem("user_data"))?.id
      }`
    );
    dispatch(
      addToCart(cartAmount === null || cartAmount?.error ? 0 : cartAmount, [])
    );
  };

  const getUserData = async (user_id) => {
    await fetchPost(UPDATE_CUSTOMER_SETTINGS, {
      fcm_token: fcm_token,
      customer_id: user_id,
    });
    dispatch(getUser(user_id));
    const response = await fetchGet(`${GET_USER_URL}?customerId=${user_id}`);
    if (response?.error) {
      dispatch(checkIsAuthError());
      return false;
    }
    const userData = JSON.parse(localStorage.getItem("user_data"));
    userData.email = response.updated_email;
    localStorage.setItem("user_data", JSON.stringify(userData));
    return dispatch(checkIsAuthSuccess());
  };

  const home = async () => {
    const user_id = localStorage.getItem("user_id");
    const lngValue = localStorage.getItem("storeLang");
    if (lngValue !== null) strings.setLanguage(lngValue);
    else strings.setLanguage("en");
    if (user_id !== null) {
      await getUserData(user_id);
      setIsLoading(false);
    } else {
      dispatch(checkIsAuthError());
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const userData = localStorage.getItem("user_data");

  let socket = null;

  if (isAuth) {
    socket = io(SERVER_URL, {
      auth: {
        token: JSON.parse(userData)?.auth_token,
      },
    });
  }

  return (
    <>
      <ToastContainer />
      <Router history={history}>
        <ThemeProvider theme={theme}>
          {isAuth ? (
            <>
              <Header socket={socket} />
              <Routes>
                <Route
                  path='*'
                  element={<Navigate replace to='/dashboard' />}
                />
                <Route
                  path='/dashboard'
                  element={
                    <Suspense fallback={<Loader />}>
                      <Dashboard socket={socket} />
                    </Suspense>
                  }
                />
                <Route
                  path='/orders'
                  element={
                    <Suspense fallback={<Loader />}>
                      <Orders socket={socket} />
                    </Suspense>
                  }
                />
                <Route
                  path='/new'
                  element={
                    <Suspense fallback={<Loader />}>
                      <NewOrder socket={socket} />
                    </Suspense>
                  }
                />
                <Route
                  path='/checkout'
                  element={
                    <Suspense fallback={<Loader />}>
                      <Checkout socket={socket} />
                    </Suspense>
                  }
                />
                <Route
                  path='/verify-email'
                  element={
                    <Suspense fallback={<Loader />}>
                      <EmailVerification />
                    </Suspense>
                  }
                />
              </Routes>
              <Footer />
            </>
          ) : (
            <Routes>
              <Route path='*' element={<Navigate replace to='/login' />} />
              <Route
                path='/login'
                element={
                  <Suspense fallback={<Loader />}>
                    <GoogleReCaptchaProvider
                      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                      <Login />
                    </GoogleReCaptchaProvider>
                  </Suspense>
                }
              />
              <Route
                path='/signUp'
                element={
                  <Suspense fallback={<Loader />}>
                    <GoogleReCaptchaProvider
                      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                      <Register />
                    </GoogleReCaptchaProvider>
                  </Suspense>
                }
              />
              <Route
                path='/get-started'
                element={
                  <Suspense fallback={<Loader />}>
                    <GetStarted />
                  </Suspense>
                }
              />
              <Route
                path='/forgot-password'
                element={
                  <Suspense fallback={<Loader />}>
                    <ForgotPassword />
                  </Suspense>
                }
              />
              <Route
                path='/verify-email'
                element={
                  <Suspense fallback={<Loader />}>
                    <EmailVerification />
                  </Suspense>
                }
              />
              <Route
                path='/forgot'
                element={
                  <Suspense fallback={<Loader />}>
                    <Forgot />
                  </Suspense>
                }
              />
            </Routes>
          )}
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
