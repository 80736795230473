/** @format */

import { fetchPost, fetchGet } from "../../config/request";
import {
  CLEAR_NOTIFICATIONS,
  CONTACT_US,
  CREATE_ORDER_URL,
  GET_INVOICE,
  GET_MESSAGE_BY_ORDER_ID,
  GET_NOTIFICATIONS,
  GET_ORDER_BY_ID,
  GET_ORDER_ITEMS_URL,
  GET_ORDER_STATUS,
  GET_ORDER_URL,
  ORDER_COUNT_URL,
  SHARE_ORDER,
  UNSEEN_NOTIFICATION_COUNT,
  UPDATE_NOTIFICATION_COUNT,
} from "../../config/Constants";
import {
  serviceActiveOrdersPending,
  serviceActiveOrdersError,
  serviceActiveOrdersSuccess,
  serviceActionPending,
  serviceActionError,
  serviceActionSuccess,
  orderCountError,
  orderCountSuccess,
} from "../../actions/HomeActions";
import {
  activeOrdersError,
  activeOrdersPending,
  activeOrdersSuccess,
  getOrderByIdError,
  getOrderByIdSuccess,
  getOrderId,
  getOrderInvoiceError,
  getOrderInvoiceSuccess,
  getOrderItems,
  getOrderItemsError,
  getOrderItemsSuccess,
  getOrderStatus,
  getOrderStatusError,
  getOrderStatusSuccess,
  orderTabPage,
  shareOrderError,
  shareOrderSuccess,
} from "../../actions/MyOrdersActions";
import { reset } from "../../actions/CartActions";
import {
  submitOrder,
  submitOrderError,
  submitOrderSuccess,
} from "../../actions/CheckoutAction";
import { addToCart } from "../../actions/ProductActions";
import {
  clearNotificationsError,
  clearNotificationsSuccess,
  getMessagesByOrderIdError,
  getMessagesByOrderIdSuccess,
  getNotificationsError,
  getNotificationsOnClickError,
  getNotificationsOnClickPending,
  getNotificationsOnClickSuccess,
  getNotificationsSuccess,
  getUnseenNotificationCountError,
  getUnseenNotificationCountSuccess,
  setLoadMore,
  updateNotificationCountError,
  updateNotificationCountSuccess,
} from "../../actions/CommonAction";
import { contactUsError, contactUsPending } from "../../actions/ContactActions";

export async function getCompletedOrders(dispatch, orderLoader, offset, limit) {
  try {
    dispatch(orderTabPage(0));
    if (orderLoader) dispatch(serviceActiveOrdersPending(offset));
    const getOrder = await fetchPost(GET_ORDER_URL, {
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
      isStatusWithComplete: true,
      offset,
      limit,
    });
    if (getOrder.error)
      return dispatch(serviceActiveOrdersError(getOrder.error));
    dispatch(serviceActiveOrdersSuccess(getOrder));
    return true;
  } catch (e) {
    return dispatch(serviceActiveOrdersError(e));
  }
}

export async function getOrderCounts(dispatch) {
  try {
    const orderCount = await fetchGet(
      `${ORDER_COUNT_URL}?customer_id=${
        JSON.parse(localStorage.getItem("user_data")).id
      }`
    );
    if (orderCount.error) dispatch(orderCountError(orderCount.error));
    dispatch(orderCountSuccess(orderCount));
  } catch (e) {
    return dispatch(serviceActiveOrdersError(e));
  }
}

export const onGetOrderStatus = async (dispatch, orderId) => {
  dispatch(getOrderStatus());
  const status = await fetchGet(`${GET_ORDER_STATUS}?order_id=${orderId}`);
  if (status.error) {
    return dispatch(getOrderStatusError(status.error));
  }
  return dispatch(getOrderStatusSuccess(status));
};

export const getOrderDetails = async (dispatch, orderId) => {
  dispatch(getOrderItems());
  const orderData = await fetchGet(
    `${GET_ORDER_ITEMS_URL}?order_id=${orderId}`
  );
  if (orderData?.error) return dispatch(getOrderItemsError(orderData?.error));
  dispatch(getOrderItemsSuccess(orderData));
};

export const shareOrder = async (dispatch, data, orderId) => {
  const orderData = await fetchPost(`${SHARE_ORDER}`, {
    orderId: orderId,
    receiver_email: data.email,
    subject: data.subject,
    from: data.from,
    to: data.to,
  });
  if (orderData?.error) return dispatch(shareOrderError(orderData?.error));
  else {
    dispatch(shareOrderSuccess(orderData));
  }
};

export const getNotifications = async (dispatch, user_id, offset) => {
  const notification = await fetchPost(`${GET_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "customer",
    offset,
    limit: 10,
  });
  if (notification?.error)
    return dispatch(getNotificationsError(notification?.error));
  else {
    dispatch(getNotificationsSuccess(notification));
    if (notification.messages.length === 0) dispatch(setLoadMore(false));
  }
};

export const clearNotifications = async (dispatch, user_id) => {
  const notification = await fetchPost(`${CLEAR_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "customer",
  });
  if (notification?.error)
    return dispatch(clearNotificationsError(notification?.error));
  else {
    dispatch(clearNotificationsSuccess(notification));
  }
};

export const getNotificationsOnClick = async (dispatch, user_id, offset) => {
  dispatch(getNotificationsOnClickPending());
  const notification = await fetchPost(`${GET_NOTIFICATIONS}`, {
    receiver_id: user_id,
    receiver_type: "customer",
    offset,
    limit: 6,
  });
  if (notification?.error)
    return dispatch(getNotificationsOnClickError(notification?.error));
  else {
    dispatch(getNotificationsOnClickSuccess(notification));
    if (notification.messages.length === 0) dispatch(setLoadMore(false));
  }
};

export const updateNotificationCount = async (
  dispatch,
  user_id,
  notification_id
) => {
  let updateNotification;
  if (notification_id) {
    updateNotification = await fetchGet(
      `${UPDATE_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=customer&notification_id=${notification_id}`
    );
  } else {
    updateNotification = await fetchGet(
      `${UPDATE_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=customer`
    );
  }
  if (updateNotification?.error)
    return dispatch(updateNotificationCountError(updateNotification?.error));
  else {
    dispatch(updateNotificationCountSuccess(updateNotification));
  }
};

export const getUnseenNotificationCount = async (dispatch, user_id) => {
  const unseenNotification = await fetchGet(
    `${UNSEEN_NOTIFICATION_COUNT}?receiver_id=${user_id}&receiver_type=customer`
  );
  if (unseenNotification?.error)
    return dispatch(getUnseenNotificationCountError(unseenNotification?.error));
  else {
    dispatch(getUnseenNotificationCountSuccess(unseenNotification));
  }
};

export const getMessagesByOrderId = async (dispatch, orderId, offset) => {
  const messagesByOrderId = await fetchGet(
    `${GET_MESSAGE_BY_ORDER_ID}?offset=${offset}&limit=500&order_id=${orderId}`
  );
  if (messagesByOrderId?.error)
    return dispatch(getMessagesByOrderIdError(messagesByOrderId?.error));
  else {
    dispatch(getMessagesByOrderIdSuccess(messagesByOrderId));
  }
};

export const getOrderById = async (dispatch, orderId) => {
  const orderDetail = await fetchGet(`${GET_ORDER_BY_ID}/${orderId}`);
  if (orderDetail.error) dispatch(getOrderByIdError(orderDetail.error));
  else dispatch(getOrderByIdSuccess(orderDetail));
};

export const getMyActiveOrders = async (dispatch, offset, limit) => {
  dispatch(activeOrdersPending(offset));
  const activeOrderList = await fetchPost(GET_ORDER_URL, {
    customer_id: JSON.parse(localStorage.getItem("user_data")).id,
    isStatusWithComplete: false,
    offset,
    limit,
  });

  if (activeOrderList.error)
    return dispatch(activeOrdersError(activeOrderList.error));
  dispatch(activeOrdersSuccess(activeOrderList));
};

export async function getCustomerOrders(dispatch, orderLoader, offset, limit) {
  try {
    dispatch(orderTabPage(0));
    if (orderLoader) dispatch(serviceActionPending(offset));
    const getOrder = await fetchPost(GET_ORDER_URL, {
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
      isStatusWithComplete: true,
      offset,
      limit,
    });
    if (getOrder.error) return dispatch(serviceActionError(getOrder.error));
    dispatch(serviceActionSuccess(getOrder));
    return true;
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export const getOrderInvoice = async (dispatch, orderId) => {
  const orderDetail = await fetchGet(`${GET_INVOICE}/${orderId}`);

  const file = new Blob([orderDetail], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
  if (orderDetail.error) dispatch(getOrderInvoiceError(orderDetail.error));
  else dispatch(getOrderInvoiceSuccess(orderDetail));
};

export async function addOrder(
  dispatch,
  deals_id,
  cardList,
  setOrderDetails,
  setCartAddress,
  totalAmount,
  dateValue,
  discountAmount,
  amount,
  cardFormValue,
  addressInfo
) {
  dispatch(submitOrder());

  const products = [];
  cardList.map((cardListValue) => {
    products.push({
      product_id: cardListValue.product_id,
      service_id: cardListValue.service_id,
      qty: 1,
      price: cardListValue.amount,
      product_addon_name: cardListValue.product_fee_name
        ? cardListValue.product_fee_name
        : "",
      product_addon_amount: cardListValue.product_fee_amount,
    });
  });
  const body = {
    customer_id: JSON.parse(localStorage.getItem("user_data")).id.toString(),
    service_address_id: addressInfo?.id || cardList[0].service_address_id,
    pickup_date: dateValue,
    pickup_time: null,
    delivery_date: null,
    delivery_time: null,
    total: totalAmount,
    discount: discountAmount,
    sub_total: amount,
    deals_id: deals_id ? deals_id : null,
    delivery_cost: null,
    delivered_by: addressInfo?.partner_id,
    payment_mode: "after",
    service_id: cardList[0].service_id,
    service_addon_name: cardList[0].service_fee_name
      ? cardList[0].service_fee_name
      : "",
    service_addon_amount: cardList[0].service_fee,
    products,
    order_name: cardFormValue?.order_name || "",
    customer_comments: cardFormValue?.order_notes || "",
    billing_address_id: Number(1),
  };

  const insertOrder = await fetchPost(CREATE_ORDER_URL, body);
  if (insertOrder.error) {
    dispatch(submitOrderError());
    return false;
  }
  dispatch(getOrderId(insertOrder.order_id));
  await getMyActiveOrders(dispatch, 0, 100);
  setOrderDetails(null);
  setCartAddress(null);
  dispatch(addToCart(0, []));
  dispatch(submitOrderSuccess());
  dispatch(reset());
  try {
    localStorage.removeItem("cardOptionalValue");
    return true;
  } catch (exception) {
    return false;
  }
}

export const pendingPaymentFilter = (orders) => {
  return orders?.filter((order) => order?.customer_status?.payment_flow);
};

export const pendingOrderFilter = (orders) => {
  return orders?.filter((order) => !order?.customer_status?.is_completed);
};

export const onContactUs = async (dispatch, state) => {
  dispatch(contactUsPending());
  const body = {
    name: JSON.parse(localStorage.getItem("user_data"))?.customer_name,
    email: state?.email,
    message: state?.query,
    phone: state?.phone?.replace(/[- )(]/g, ""),
  };
  const contact = await fetchPost(`${CONTACT_US}`, body);
  if (contact?.error) {
    dispatch(contactUsError(contact?.error));
    return false;
  } else {
    return true;
  }
};
