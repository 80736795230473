/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onSubmitForgotRequest } from "../actions/ForgotPasswordActions";
import { submitVerifyEmail } from "../components/account/service";
import { toast } from "react-toastify";
import {
  logo99,
  verify_email_success,
  verify_email_fail,
} from "../config/Constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../components/GeneralComponents";
import useStyles from "../GlobalStyle";
import useMediaQuery from "@mui/material/useMediaQuery";
import localStyles from "./localStyles";

const EmailVerification = () => {
  const GlobalStyle = useStyles();
  const dispatch = useDispatch();
  const styles = localStyles();
  const navigate = useNavigate();
  const [res, setRes] = useState(false);
  const [message, setMessage] = useState("");
  const [messagData, setMessageData] = useState("");
  const [load, setLoad] = useState(true);

  const { isAuth } = useSelector((state) => state.auth);

  let [searchParams] = useSearchParams();
  const verificationCode = searchParams.get("verificationCode");
  const mode = searchParams.get("mode");

  useEffect(() => {
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    dispatch(onSubmitForgotRequest());
    setLoad(true);
    try {
      const response = await submitVerifyEmail(
        dispatch,
        verificationCode,
        mode
      );
      setRes(response);
      if (response[0]) {
        setLoad(false);
        toast.success(verify_email_success);
        setMessage("Congratulations");
        setMessageData("You have successfully verified your email.");
      } else {
        if (response[1].includes("already in use")) {
          setMessage("Sorry");
          setMessageData(`${response[1]}`);
        } else {
          setMessage("Sorry");
          setMessageData(
            "There is an issue while verifying your email. Please try again."
          );
        }
        toast.alert(verify_email_fail);
      }
    } catch (err) {
      toast.error(verify_email_fail);
    }
  };

  const LoginNow = () => {
    if (isAuth) navigate("/dashboard");
    else navigate("/login");
  };

  const matches = useMediaQuery("(max-width:1023px)");

  return (
    <div className={GlobalStyle.container}>
      <div className={styles.header_section} />
      <div className={styles.bottom_section}>
        <div className={styles.card}>
          <div className={GlobalStyle.logoCard}>
            <img width={matches ? 240 : 292} src={logo99} alt='' />
          </div>
          <div className={styles.inputs}>
            {load === true && res === false ? (
              <Loader />
            ) : (
              <>
                <div
                  className={GlobalStyle.inputHeader}
                  style={{ textAlign: "center" }}>
                  {message}
                </div>
                <div className={styles.verification}>{messagData}</div>
                {res && (
                  <button
                    className={GlobalStyle.mainBtnGlobal}
                    onClick={LoginNow}>
                    {isAuth ? "Go To Dashboard" : "Login Now"}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
