/** @format */

import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 560,
      sm: 768,
      md: 1023,
      lg: 1250,
      pro13: 1280,
      air11: 1366,
      air13: 1440,
      custom: 1510,
      xl: 1536,
      pro15: 1690,
      pro19: 1910,
    },
  },
});
