/** @format */

import { isInputFalse } from "../../actions/CommonAction";
import {
  profileErrorMessage,
  updateCustomerError,
  updateCustomerSuccess,
  updateProfilePictureError,
  updateProfilePictureSuccess,
  updateServiceActionError,
  updateServiceActionPending,
  updateServiceActionSuccess,
} from "../../actions/ProfileActions";
import {
  ADD_CARD_URL,
  ADD_CARD_WITH_TOKEN,
  CREATE_ADDRESS_URL,
  CREATE_SERVICE_AREA_REQUEST_URL,
  DELETE_ADDRESS,
  DELETE_CARD_URL,
  EDIT_CARD_URL,
  GET_BILLING_ADDRESS_URL,
  GET_CARD_URL,
  GET_CUSTOMER_ADDRESS,
  GET_USER_URL,
  RESET_PASSWORD_URL,
  FORGOT_PASSWORD_URL,
  VERIFY_EMAIL,
  UPDATE_ADDRESS_URL,
  UPDATE_CUSTOMER,
  UPLOAD_PROFILE_IMAGE,
  GET_GEOCODE_LOCATION,
} from "../../config/Constants";
import { getUser } from "../../config/Helper";
import { fetchGet, fetchPost } from "../../config/request";
import {
  onSubmitResetRequestError,
  onSubmitResetRequestSuccess,
  resetPasswordValueError,
} from "../../actions/ResetPasswordActions";
import {
  getAddressValue,
  getBillingAddress,
  getBillingAddressError,
  getBillingAddressSuccess,
  serviceActionPending,
  serviceActionError,
  serviceActionSuccess,
  editServiceActionPending,
  editServiceActionError,
  editServiceActionSuccess,
} from "../../actions/AddressActions";
import {
  listServiceActionError,
  listServiceActionPending,
  listServiceActionSuccess,
} from "../../actions/AddressListActions";
import {
  addCardError,
  addCardPending,
  addCardSuccess,
  addCardWithTokenError,
  editCardError,
  editCardPending,
  editCardSuccess,
  getCardListError,
  getCardListPending,
  getCardListSuccess,
} from "../../actions/CardListActions";
import {
  requestServiceAreaError,
  requestServiceAreaPending,
  requestServiceAreaSuccess,
} from "../../actions/ServicesAction";

export async function getSignedLink(dispatch, response, profileError) {
  try {
    const imageUri = await fetchPost(UPLOAD_PROFILE_IMAGE, {
      customer_id: JSON.parse(localStorage.getItem("user_data"))?.id,
      file: {
        base64: response.url.split(",")[1],
        fileName: response.file.name,
        type: response.file.type,
      },
    });
    if (imageUri.error) {
      dispatch(
        profileErrorMessage({
          ...profileError,
          api_error: imageUri.error,
        })
      );
      return dispatch(updateProfilePictureError());
    }
    await getUser(JSON.parse(localStorage.getItem("user_data"))?.id);
    dispatch(updateProfilePictureSuccess());
  } catch (e) {
    dispatch(
      profileErrorMessage({
        ...profileError,
        api_error: "some error",
      })
    );
    return e;
  }
}

export async function updateProfile(dispatch, profileInput, profileError) {
  try {
    let tel = profileInput.phone_number;
    tel = tel.replace(/\D+/g, "");
    const body = { ...profileInput, phone_number: tel };
    dispatch(updateServiceActionPending());
    const update = await fetchPost(UPDATE_CUSTOMER, body);
    if (update?.error) {
      dispatch(
        profileErrorMessage({
          ...profileError,
          api_error: update?.error,
        })
      );
      dispatch(updateServiceActionError(update?.error));
      return false;
    }
    await getUser(JSON.parse(localStorage.getItem("user_data"))?.id);
    dispatch(isInputFalse());
    dispatch(updateServiceActionSuccess());
    return true;
  } catch (e) {
    dispatch(
      profileErrorMessage({
        ...profileError,
        api_error: "some error",
      })
    );
    dispatch(updateServiceActionError(e));
    return false;
  }
}

export async function updateCustomerProfile(
  dispatch,
  userData,
  customer_name,
  companyName,
  phoneNumber,
  email
) {
  try {
    const body = {
      customer_id: userData.id,
      customer_name: customer_name,
      phone_number: phoneNumber.toString().replace(/[- )(]/g, ""),
      email: email,
      company_name: companyName,
    };

    const update = await fetchPost(UPDATE_CUSTOMER, body);
    if (update.error) {
      dispatch(updateCustomerError(update.error));
      return false;
    } else {
      const response = await fetchGet(
        `${GET_USER_URL}?customerId=${userData.id}`
      );

      const userData = JSON.parse(localStorage.getItem("user_data"));
      userData.email = response.updated_email;
      localStorage.setItem("user_data", JSON.stringify(userData));

      dispatch(
        updateCustomerSuccess({
          message: update?.message,
          customer_id: userData.id,
          customer_name: userData.customer_name,
          phone_number: phoneNumber,
          email: userData.email,
          company_name: companyName,
        })
      );
      return true;
    }
  } catch (e) {
    return { error: e };
  }
}

export async function submitResetPassword(
  dispatch,
  formData,
  resetPasswordError
) {
  try {
    const body = {
      currentPassword: formData.current_password,
      newPassword: formData.confirm_password,
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
    };
    const reqResetPassword = await fetchPost(RESET_PASSWORD_URL, body);
    if (reqResetPassword.error) {
      dispatch(onSubmitResetRequestError(reqResetPassword.error));
      dispatch(
        resetPasswordValueError({
          ...resetPasswordError,
          api_error: reqResetPassword.error,
        })
      );
      return false;
    }
    dispatch(onSubmitResetRequestSuccess(reqResetPassword.message));
    return true;
  } catch (e) {
    dispatch(onSubmitResetRequestError(e));
    dispatch(
      resetPasswordValueError({
        ...resetPasswordError,
        api_error: "some error",
      })
    );
    return e;
  }
}

export async function submitForgotPassword(
  dispatch,
  formData,
  resetPasswordError,
  token
) {
  try {
    const body = {
      password: formData.new_password,
      code: token,
    };
    const reqResetPassword = await fetchPost(FORGOT_PASSWORD_URL, body);
    if (reqResetPassword.error) {
      dispatch(onSubmitResetRequestError(reqResetPassword.error));
      dispatch(
        resetPasswordValueError({
          ...resetPasswordError,
          api_error: reqResetPassword.error,
        })
      );
      return false;
    }
    dispatch(onSubmitResetRequestSuccess(reqResetPassword.message));
    return true;
  } catch (e) {
    dispatch(onSubmitResetRequestError(e));
    dispatch(
      resetPasswordValueError({
        ...resetPasswordError,
        api_error: "some error",
      })
    );
    return e;
  }
}

export async function submitVerifyEmail(dispatch, verificationCode, mode) {
  try {
    const reqResetPassword = await fetchGet(
      `${VERIFY_EMAIL}?mode=${mode}&verificationCode=${verificationCode}`
    );
    console.log(reqResetPassword, "e1");
    if (reqResetPassword.error) {
      dispatch(onSubmitResetRequestError(reqResetPassword.error));
      dispatch(
        resetPasswordValueError({
          api_error: reqResetPassword.error,
        })
      );
      return [false, reqResetPassword.error];
    }
    dispatch(onSubmitResetRequestSuccess(reqResetPassword.message));
    return [true, ""];
  } catch (e) {
    dispatch(onSubmitResetRequestError(e));
    dispatch(
      resetPasswordValueError({
        api_error: "some error",
      })
    );
    console.log(e, "error");
    return [false, e];
  }
}

export async function getCustomerBillingAddress(dispatch, user_id) {
  try {
    dispatch(getBillingAddress());
    const response = await fetchGet(
      `${GET_BILLING_ADDRESS_URL}?customer_id=${user_id}`
    );
    if (response.error) dispatch(getBillingAddressError(response.error));
    else dispatch(getBillingAddressSuccess(response));
  } catch (e) {
    dispatch(getBillingAddressError(e));
    return { error: e };
  }
}

export async function getCurrentLocation(lat, lng) {
  try {
    const response = await fetchGet(
      `${GET_GEOCODE_LOCATION}?latitude=${lat}&longitude=${lng}`
    );
    return response.data;
  } catch (e) {
    return { error: e };
  }
}

export async function getAddressList(dispatch, isFromCheckout = false) {
  try {
    dispatch(listServiceActionPending());
    const addressList = await fetchGet(
      `${GET_CUSTOMER_ADDRESS}?customer_id=${
        JSON.parse(localStorage.getItem("user_data")).id
      }`
    );

    if (addressList.error) dispatch(listServiceActionError(addressList.error));
    else {
      if (isFromCheckout) {
        addressList[addressList.length - 1].isSelected = true;
        addressList[addressList.length - 1].isUpdated = true;
        dispatch(getAddressValue(addressList[addressList.length - 1]));
      }
      dispatch(listServiceActionSuccess(addressList));
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function getCustomerCards(dispatch) {
  try {
    dispatch(getCardListPending());
    const cardList = await fetchGet(
      `${GET_CARD_URL}?customer_id=${
        JSON.parse(localStorage.getItem("user_data")).id
      }`
    );

    if (cardList.error) dispatch(getCardListError(cardList.error));
    else {
      dispatch(getCardListSuccess(cardList));
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function addCard(dispatch, state) {
  try {
    dispatch(addCardPending());
    const body = {
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
      card_number: state?.cardNumber?.replace(/\s/g, ""),
      card_exp_month: state?.expiryDate?.split("/")[0],
      card_exp_year: state?.expiryDate?.split("/")[1],
      card_cvc: state?.cvc,
      is_default_billing_address: state?.billingAddress,
      address: {
        ...state?.address,
        customer_id: JSON.parse(
          localStorage.getItem("user_data")
        ).id.toString(),
        address_name: state?.address?.address,
        is_default_billing: false,
        line_2: state?.address?.line_1 ?? "",
      },
    };
    const cardList = await fetchPost(ADD_CARD_URL, body);
    if (cardList.error) {
      dispatch(addCardError(cardList.error));
      return false;
    } else {
      dispatch(addCardSuccess(cardList));
      getCustomerCards(dispatch);
      return true;
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function addCardWithToken(dispatch, state) {
  try {
    if (state?.address?.id) delete state.address.id;
    dispatch(addCardPending());
    const body = {
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
      stripeTokenId: state?.cardDetails?.id,
      is_default_billing_address: state?.is_default_billing_address,
      address: {
        ...state?.address,
        customer_id: JSON.parse(
          localStorage.getItem("user_data")
        ).id.toString(),
        address_name: state?.address?.address,
        is_default_billing: false,
        line_2: state?.address?.line_1 ?? "",
      },
    };
    const cardList = await fetchPost(ADD_CARD_WITH_TOKEN, body);
    if (cardList.error) {
      dispatch(addCardWithTokenError(cardList.error));
      return false;
    } else {
      getCustomerCards(dispatch);
      return true;
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function editCard(dispatch, state, selectedCard) {
  try {
    dispatch(editCardPending());
    const body = {
      stripeCardId: selectedCard?.id,
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
      card_exp_month: state?.expiryDate?.split("/")[0],
      card_exp_year: state?.expiryDate?.split("/")[1],
      is_default_billing_address: false,
      address: {
        city_long_name:
          state?.address?.city_long_name || selectedCard?.address_city,
        country_long_name:
          state?.address?.country_long_name || selectedCard?.address_country,
        line_1: state?.address?.line_1
          ? state?.address?.line_1
          : selectedCard?.address_line1,
        line_2: state?.address?.line_1
          ? state?.address?.line_1
          : selectedCard?.address_line2,
        state_long_name:
          state?.address?.state_long_name || selectedCard?.address_state,
        pin_code: state?.address?.pin_code || selectedCard?.address_zip,
      },
    };
    const cardList = await fetchPost(EDIT_CARD_URL, body);
    if (cardList.error) {
      dispatch(editCardError(cardList.error));
      return false;
    } else {
      dispatch(editCardSuccess(cardList));
      getCustomerCards(dispatch);
      return true;
    }
  } catch (e) {
    dispatch(listServiceActionError(e));
    return { error: e };
  }
}

export async function onRequestArea(dispatch, state) {
  try {
    dispatch(requestServiceAreaPending());
    const body = {
      state: state?.stateAddress,
      city: state?.city,
      notes: state?.notes,
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
    };

    const requestArea = await fetchPost(CREATE_SERVICE_AREA_REQUEST_URL, body);

    if (requestArea.error) {
      dispatch(requestServiceAreaError(requestArea.error));
      return false;
    } else {
      dispatch(requestServiceAreaSuccess(requestArea));

      return true;
    }
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export async function onSaveAddress(
  dispatch,
  addressValue,
  isBilling,
  address,
  fromSetLocation
) {
  try {
    dispatch(serviceActionPending());
    let body;
    if (fromSetLocation) {
      body = {
        ...addressValue,
        address_name: address.address_name || "",
        address_note: address.address_note || "",
        customer_id: JSON.parse(
          localStorage.getItem("user_data")
        ).id.toString(),
        is_default_billing: false,
        is_temporary: false,
        line_2: addressValue?.line_2 || address?.line_2 || "",
      };
    } else {
      body = {
        ...addressValue,
        address_name: addressValue?.address_name || "",
        address_note: addressValue?.address_note || "",
        customer_id: JSON.parse(
          localStorage.getItem("user_data")
        ).id.toString(),
        is_default_billing: isBilling,
        line_2: addressValue?.line_2 || address?.line_2 || "",
      };
    }

    const saveAddress = await fetchPost(CREATE_ADDRESS_URL, body);

    if (saveAddress.error) {
      dispatch(serviceActionError(saveAddress.error));
      return false;
    } else {
      await getAddressList(dispatch, isBilling);

      if (isBilling) {
        await getCustomerBillingAddress(
          dispatch,
          JSON.parse(localStorage.getItem("user_data")).id.toString()
        );
      }

      dispatch(serviceActionSuccess(saveAddress.message));

      return true;
    }
  } catch (e) {
    return dispatch(serviceActionError(e));
  }
}

export async function onUpdateAddress(dispatch, addressValue) {
  dispatch(editServiceActionPending());
  const update = await fetchPost(UPDATE_ADDRESS_URL, addressValue);

  if (update.error) {
    dispatch(editServiceActionError(update.error));
    return false;
  }
  await getAddressList(dispatch, false);
  if (addressValue.is_default_billing)
    getCustomerBillingAddress(
      dispatch,
      JSON.parse(localStorage.getItem("user_data")).id.toString()
    );
  dispatch(editServiceActionSuccess(update.message));
  return true;
}

export async function onDeleteAddress(dispatch, addressId) {
  try {
    dispatch(updateServiceActionPending());
    const deleteAddress = await fetchPost(DELETE_ADDRESS, {
      address_id: addressId.toString(),
    });

    if (deleteAddress.error)
      return dispatch(updateServiceActionError(deleteAddress.error));
    await getAddressList(dispatch, false);
    dispatch(updateServiceActionSuccess(deleteAddress.message));
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}

export async function onDeleteCard(dispatch, stripeId) {
  try {
    dispatch(updateServiceActionPending());
    const deleteCard = await fetchPost(DELETE_CARD_URL, {
      stripeCardId: stripeId,
      customer_id: JSON.parse(localStorage.getItem("user_data")).id,
    });

    if (deleteCard.error)
      return dispatch(updateServiceActionError(deleteCard.error));
    await getCustomerCards(dispatch);
    dispatch(updateServiceActionSuccess(deleteCard.message));
  } catch (e) {
    dispatch(updateServiceActionError(e));
  }
}
