/** @format */

import {
  listServiceActionError,
  listServiceActionPending,
  listServiceActionSuccess,
  locationPermissionDenied,
  getNearByServiceAddress,
  getNearByServiceAddressSuccess,
  getNearByServiceAddressError,
  getTemporaryAddressPending,
  getTemporaryAddressError,
  getTemporaryAddressSuccess,
} from "../../actions/AddressListActions";
import {
  GET_NEAR_BY_SERVICE_ADDRESS,
  GET_SERVICE_ADDRESS,
  GET_TEMPORARY_ADDRESS_URL,
} from "../../config/Constants";
import { fetchGet, fetchPost } from "../../config/request";

export async function getAddressDetails(dispatch, route, latitude, longitude) {
  dispatch(listServiceActionPending());
  dispatch(locationPermissionDenied(false));
  let url = null;
  url = `${GET_SERVICE_ADDRESS}?service_id=${route.serviceId}&latitude=${latitude}&longitude=${longitude}`;

  const addressList = await fetchGet(url);
  if (addressList.error) dispatch(listServiceActionError(addressList.error));
  else dispatch(listServiceActionSuccess(addressList));
}
export async function getNearByServiceAddressList(
  dispatch,
  latitude,
  longitude,
  serviceId,
  index
) {
  let nearByServiceAddress;
  try {
    dispatch(getNearByServiceAddress());
    const body = { latitude, longitude, service_id: serviceId };
    nearByServiceAddress = await fetchPost(GET_NEAR_BY_SERVICE_ADDRESS, body);
    if (nearByServiceAddress.error) {
      dispatch(
        getNearByServiceAddressError({
          error: nearByServiceAddress.error,
          index,
        })
      );
    }
    dispatch(getNearByServiceAddressSuccess(nearByServiceAddress));
    return true;
  } catch (e) {
    dispatch(getNearByServiceAddressError(e));
  }
}

export async function getTemporaryAddress(dispatch, customer_id) {
  try {
    dispatch(getTemporaryAddressPending());
    const temporaryAddress = await fetchGet(
      `${GET_TEMPORARY_ADDRESS_URL}/${customer_id}`
    );
    if (temporaryAddress.error)
      dispatch(getTemporaryAddressError(temporaryAddress.error));
    else dispatch(getTemporaryAddressSuccess(temporaryAddress));
  } catch (e) {
    dispatch(getTemporaryAddressError(e));
    return false;
  }
}
