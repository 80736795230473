/** @format */

import * as ActionTypes from "./ActionTypes";

export const serviceActionPending = () => ({
  type: ActionTypes.HOME_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: ActionTypes.HOME_SERVICE_ERROR,
  error: error,
});

export const serviceActionSuccess = (data) => ({
  type: ActionTypes.HOME_SERVICE_SUCCESS,
  data: data,
});

export const serviceActiveOrdersPending = (offset) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_PENDING,
  offset,
});

export const serviceActiveOrdersError = (error) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_ERROR,
  error: error,
});

export const serviceActiveOrdersSuccess = (data) => ({
  type: ActionTypes.HOME_ACTIVE_ORDERS_SUCCESS,
  data: data,
});

export const getMainParentId = (mainParentId) => ({
  type: ActionTypes.MAIN_PARENT_ID,
  mainParentId,
});

export const setTabValue = (data) => ({
  type: ActionTypes.SET_TAB_VALUE,
  data,
});
export const resetTabValue = () => ({
  type: ActionTypes.RESET_TAB_VALUE,
});

export const orderCountSuccess = (data) => ({
  type: ActionTypes.ORDER_COUNT_SUCCESS,
  data,
});

export const orderCountError = (error) => ({
  type: ActionTypes.ORDER_COUNT_ERROR,
  error,
});
