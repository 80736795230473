/** @format */

import * as ActionTypes from "./ActionTypes";

export const getCardListPending = () => ({
  type: ActionTypes.CARD_LIST_PENDING,
});

export const getCardListError = (error) => ({
  type: ActionTypes.CARD_LIST_ERROR,
  error: error,
});

export const getCardListSuccess = (data) => ({
  type: ActionTypes.CARD_LIST_SUCCESS,
  data: data,
});

export const addCardPending = () => ({
  type: ActionTypes.ADD_CARD_PENDING,
});

export const addCardError = (error) => ({
  type: ActionTypes.ADD_CARD_ERROR,
  error: error,
});

export const addCardSuccess = (data) => ({
  type: ActionTypes.ADD_CARD_SUCCESS,
  data: data,
});

export const addCardWithTokenPending = () => ({
  type: ActionTypes.ADD_CARD_WITH_TOKEN_PENDING,
});

export const addCardWithTokenError = (error) => ({
  type: ActionTypes.ADD_CARD_WITH_TOKEN_ERROR,
  error: error,
});

export const addCardWithTokenSuccess = (data) => ({
  type: ActionTypes.ADD_CARD_WITH_TOKEN_SUCCESS,
  data: data,
});

export const editCardPending = () => ({
  type: ActionTypes.EDIT_CARD_PENDING,
});

export const editCardError = (error) => ({
  type: ActionTypes.EDIT_CARD_ERROR,
  error: error,
});

export const editCardSuccess = (data) => ({
  type: ActionTypes.EDIT_CARD_SUCCESS,
  data: data,
});
