/** @format */

/* eslint-disable quotes */

import React, { useContext, useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroller";

import { makeStyles } from "@mui/styles";
import {
  smallLogo99,
  normal,
  semiBold,
  IMAGE_URL,
  no_notifications,
  logout_alert,
  GET_USER_URL,
} from "../../config/Constants";
import Moment from "react-moment";

import { useNavigate } from "react-router-dom";
import * as colors from "../../assets/css/Colors";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ChatIcon from "@mui/icons-material/Chat";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Avatar, Badge, Modal, Popover } from "@mui/material";
import { checkIsAuthError } from "../../actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatter, getInitial } from "../../config/Helper";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { resetProfileData } from "../../actions/ProfileActions";
import Cart from "../../assets/img/wrench1.png";
import { DPFContext } from "../../context";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import MobileMenu from "./MobileMenu";
import {
  clearNotifications,
  getMessagesByOrderId,
  getNotifications,
  getNotificationsOnClick,
  getOrderById,
  getOrderDetails,
  getUnseenNotificationCount,
  updateNotificationCount,
} from "../ordersHistory/services";
import Tooltip from "@mui/material/Tooltip";
import { setErrorValue } from "../../actions/LoginActions";
import {
  getUnseenNotificationCountSuccess,
  updateMsgCountActive,
  updateMsgCountCompleted,
} from "../../actions/CommonAction";
import { setTabValue } from "../../actions/HomeActions";
import { Loader } from "../GeneralComponents";
import {
  newOrderCreated,
  orderStatusChanged,
} from "../../actions/MyOrdersActions";
import useStyles from "../../GlobalStyle";
import { fetchGet } from "../../config/request";
import { getTemporaryAddress } from "../newOrder/locationServices";
import { setSelectedRow } from "../../actions/CartActions";

const Header = ({ socket }) => {
  const dispatch = useDispatch();
  const styles = localStyles();
  const GlobalStyles = useStyles();

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user_data"));
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [active, setActive] = useState(false);
  const { amount } = useSelector((state) => state.product);
  const {
    notifications,
    unseenNotificationCount,
    loadMore,
    notificationLoader,
  } = useSelector((state) => state.common);

  const {
    setAccount,
    setOrderDetails,
    setIsCartShown,
    setOrdersSort,
    setSsoError,
    account,
    setContact,
    thanksModal,
    setThanksModal,
  } = useContext(DPFContext);
  const matches = useMediaQuery("(max-width:1023px)");
  const [openMenu, setOpenMenu] = useState(false);

  const userData = localStorage.getItem("user_data");

  useEffect(() => {
    if (window.location.href.includes("/orders")) {
      setActive(true);
    }
    socket.on("connect", (socketConnection) => {
      console.log("socket connected", socketConnection);
    });

    socket.on(
      `customer_notifications_${JSON.parse(userData).id}`,
      (callback) => {
        if (callback.success) {
          dispatch(getUnseenNotificationCountSuccess(callback));
        }
      }
    );

    socket?.on(
      `message_count_${JSON.parse(userData).id}_customer`,
      (callback) => {
        console.log(callback, "message_count");
        dispatch(updateMsgCountActive(callback));
        dispatch(updateMsgCountCompleted(callback));
      }
    );

    socket.on("new_order_created", (callback) => {
      console.log(callback, "order created");
      if (
        callback.success &&
        callback?.orderDetail?.customer?.id ===
          Number(localStorage.getItem("user_id"))
      ) {
        dispatch(newOrderCreated(callback.orderDetail));
      }
    });

    socket.on("status_update", (callback) => {
      console.log(callback, "order updated");
      if (
        callback.success &&
        callback?.orderDetail?.customer?.id ===
          Number(localStorage.getItem("user_id"))
      ) {
        getOrderDetails(dispatch, callback?.orderDetail?.order_id);
        getOrderById(dispatch, callback?.orderDetail?.order_id);
        dispatch(orderStatusChanged(callback.orderDetail));
      }
    });

    socket.on("order_edited", (callback) => {
      console.log(callback, "order edited");
      if (
        callback.success &&
        callback?.orderDetail?.customer?.id ===
          Number(localStorage.getItem("user_id"))
      ) {
        dispatch(orderStatusChanged(callback.orderDetail));
      }
    });

    socket.on("seen messages", (callback) => {
      console.log(callback, "seen messages");

      getMessagesByOrderId(
        dispatch,
        Number(localStorage.getItem("orderOpened")),
        0
      );
    });

    socket.on("user received message", (message) => {
      console.log("message received from server...", message);

      getMessagesByOrderId(
        dispatch,
        Number(localStorage.getItem("orderOpened")),
        0
      );
    });

    socket.on(`${JSON.parse(userData).id}_customer`, async (callback) => {
      if (callback.user_data) {
        const response = await fetchGet(
          `${GET_USER_URL}?customerId=${JSON.parse(userData).id}`
        );

        if (response?.error) {
          return false;
        } else {
          const userData = JSON.parse(localStorage.getItem("user_data"));
          userData.email = response.updated_email;
          localStorage.setItem("user_data", JSON.stringify(userData));
          return true;
        }
      }
    });
  }, [socket]);

  const showUserMenu = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserAnchorEl(null);
  };

  const showNotificationList = (event) => {
    getNotificationsOnClick(dispatch, localStorage.getItem("user_id"), 0);
    setNotificationAnchorEl(event.currentTarget);
  };

  const closeNotificationList = () => {
    setNotificationAnchorEl(null);
  };

  useEffect(() => {
    getNotifications(dispatch, localStorage.getItem("user_id"), 0);
    getUnseenNotificationCount(dispatch, localStorage.getItem("user_id"));
    getTemporaryAddress(dispatch, localStorage.getItem("user_id"));
  }, []);

  const userMenu = Boolean(userAnchorEl);
  const userId = userMenu ? "user-popover" : undefined;
  const notificationList = Boolean(notificationAnchorEl);
  const notificationId = notificationList ? "notification-popover" : undefined;

  const openChatBubble = () => {
    setContact(true);
    setAccount(null);
    setOrderDetails(null);
    setOrdersSort(null);
    closeMenu();
    closeUserMenu();
  };

  const logOut = async () => {
    socket?.disconnect();
    await signOut(auth);
    dispatch(setErrorValue());
    setSsoError(null);
    dispatch(resetProfileData());
    dispatch(checkIsAuthError());

    localStorage.removeItem("orderOpened");
    localStorage.removeItem("previouslyOpened");
    localStorage.removeItem("user_data");
    localStorage.removeItem("order_details_service");
    localStorage.removeItem("order_details_address");
    localStorage.removeItem("user_id");
    localStorage.removeItem("customer_name");
    localStorage.removeItem("id");
    toast.success(logout_alert);
  };

  const openAccount = () => {
    navigate("/dashboard");
    setOrderDetails(null);
    setAccount(true);
    closeMenu();
    closeUserMenu();
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const goToMainPage = () => {
    navigate("/dashboard");

    setAccount(null);
    setActive(false);
  };

  const openCart = () => {
    if (matches) {
      setIsCartShown(true);
      navigate("/new");
    } else navigate("/new");
  };

  const loadMoreData = (page) => {
    getNotifications(dispatch, localStorage.getItem("user_id"), 6 * page);
  };

  const handleClearNotifications = () => {
    clearNotifications(dispatch, localStorage.getItem("user_id"));
    closeNotificationList();
  };

  const handleMarkAsRead = () => {
    updateNotificationCount(dispatch, localStorage.getItem("user_id"));
    closeNotificationList();
  };

  const openChatModule = (type, id, n_id) => {
    updateNotificationCount(dispatch, localStorage.getItem("user_id"), n_id);
    dispatch(setSelectedRow(id));
    if (window.location.href.includes("/new")) navigate("/dashboard");
    setOrderDetails(id);
    closeNotificationList();
    if (type === "CHAT_NOTIFICATION") {
      dispatch(setTabValue(2));
    } else {
      dispatch(setTabValue(1));
    }
    getUnseenNotificationCount(dispatch, localStorage.getItem("user_id"));
  };

  const messagePreview = (notification) => {
    if (notification?.message?.messageType === "text") {
      return `${notification?.message?.message}`;
    }
    if (notification?.message?.messageType === "file") {
      return "Shared a pdf";
    }
    if (notification?.message?.messageType === "image") {
      return (
        <img src={notification?.message?.url} className={styles.imgDiv}></img>
      );
    }
  };
  const navigateUrl = () => {
    navigate("/orders");
    setActive(true);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <div className={styles.logo} onClick={goToMainPage}>
            <Tooltip title='Home'>
              <img src={smallLogo99} alt='' width={matches ? 60 : 100} />
            </Tooltip>
          </div>
          {!matches && (
            <div
              className={active ? styles.focus : styles.orders}
              onClick={navigateUrl}>
              <span>Orders</span>
            </div>
          )}
        </div>

        <div className={styles.rightBlock}>
          <Tooltip title='Service Order'>
            <div className={styles.cart} onClick={openCart}>
              <span>{formatter.format(amount)}</span>
              <img src={Cart} alt='' width={25} />
            </div>
          </Tooltip>
          <div className={styles.notificationsIcon}>
            <Badge
              badgeContent={unseenNotificationCount}
              classes={{ badge: styles.badgeSize }}>
              <Tooltip title='Notifications'>
                <NotificationsIcon
                  sx={{
                    height: "40px",
                    width: "40px",
                    color: colors.theme_fg,
                  }}
                  onClick={showNotificationList}
                />
              </Tooltip>
            </Badge>
            <Popover
              id={notificationId}
              open={notificationList}
              anchorEl={notificationAnchorEl}
              onClose={closeNotificationList}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              sx={{ top: "5px" }}
              className={styles.notificationPaper}>
              <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={(page) => loadMoreData(page)}
                hasMore={loadMore}
                useWindow={false}>
                <div className={styles.notifications}>
                  {notificationLoader ? (
                    <Loader />
                  ) : notifications.length > 0 ? (
                    notifications?.map((notification) => (
                      <div
                        key={notification?.id}
                        className={styles.notification}
                        style={
                          notification.seen
                            ? {
                                backgroundColor: "#EDEDED",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "white",
                                cursor: "pointer",
                              }
                        }
                        onClick={() =>
                          openChatModule(
                            notification?.notification_type,
                            notification?.order_id,
                            notification?.id
                          )
                        }>
                        {notification?.notification_type ===
                        "CHAT_NOTIFICATION" ? (
                          <ChatIcon
                            style={{ background: "none", boxShadow: "none" }}
                          />
                        ) : (
                          <NotificationsIcon
                            style={{ background: "none", boxShadow: "none" }}
                          />
                        )}
                        <div className={styles.notificationInfo}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}>
                            <span
                              className={styles.notificationName}
                              style={
                                notification.seen
                                  ? {
                                      fontWeight: 600,
                                    }
                                  : {
                                      fontWeight: "bold",
                                    }
                              }>
                              {notification?.notification_type ===
                              "ORDER_CREATE"
                                ? `Order #${notification?.order?.customer_order_id} is created`
                                : notification?.notification_type ===
                                  "CHAT_NOTIFICATION"
                                ? `New message for Order #${notification?.order?.customer_order_id}`
                                : `Order #${notification?.order?.customer_order_id} was updated`}
                            </span>
                            {notification.created_at && (
                              <span>
                                <Moment
                                  style={
                                    notification.seen
                                      ? {
                                          fontSize: "12px",
                                          color: "#898989",
                                          fontWeight: 400,
                                        }
                                      : {
                                          fontSize: "12px",
                                          color: "#898989",
                                          fontWeight: "bold",
                                        }
                                  }
                                  fromNow>
                                  {notification.created_at}
                                </Moment>
                              </span>
                            )}
                          </div>
                          <span
                            className={styles.notificationDescription}
                            style={
                              notification?.message?.messageType === "image"
                                ? { height: "150px" }
                                : {}
                            }>
                            {notification?.notification_type === "ORDER_CREATE"
                              ? `Your order has been created. Tap to view.`
                              : notification?.notification_type ===
                                "CHAT_NOTIFICATION"
                              ? messagePreview(notification)
                              : `Your order has been updated. Tap to view.`}
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className={styles.noNotification}>{no_notifications}</p>
                  )}
                </div>
              </InfiniteScroll>
              {notifications.length > 0 && (
                <div className={styles.notificationFooter}>
                  <p
                    className={styles.clearNotification}
                    onClick={() => {
                      handleClearNotifications();
                    }}>
                    Clear Notifications
                  </p>
                  <p
                    className={styles.clearNotification}
                    style={{ color: "#c90000", fontWeight: "bold" }}
                    onClick={() => {
                      handleMarkAsRead();
                    }}>
                    Mark all as Read
                  </p>
                </div>
              )}
            </Popover>
          </div>
          {matches ? (
            <>
              <MenuIcon
                fontSize={"large"}
                sx={{ color: colors.theme_fg }}
                onClick={() => setOpenMenu(!openMenu)}
              />
              <MobileMenu
                openMenu={openMenu}
                closeMenu={closeMenu}
                openAccount={openAccount}
                logOut={logOut}
              />
            </>
          ) : (
            <>
              <div className={styles.userInfo} onClick={showUserMenu}>
                <Avatar
                  variant='rounded'
                  src={`${IMAGE_URL}${user.profile_picture}`}
                  sx={{ width: "40px", height: "40px" }}>
                  {getInitial(user.customer_name)}
                </Avatar>
                <div className={styles.info}>
                  <span className={styles.name}>{user.customer_name}</span>
                  {user.company && <span>{user.company.name}</span>}
                </div>
                <ArrowDropDownIcon className={userAnchorEl && styles.active} />
              </div>
              <Popover
                id={userId}
                open={userMenu}
                anchorEl={userAnchorEl}
                onClose={closeUserMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ top: "5px" }}>
                <div className={styles.userMenu}>
                  <span
                    onClick={openAccount}
                    className={styles.userMenuItem}
                    style={account ? { backgroundColor: "#c9c0c0" } : {}}>
                    Account
                  </span>
                  <span onClick={closeUserMenu} className={styles.userMenuItem}>
                    <a
                      href={"https://www.99dpf.com/"}
                      target='_blank'
                      rel='noreferrer'>
                      FAQ
                    </a>
                  </span>
                  <span
                    onClick={openChatBubble}
                    className={styles.userMenuItem}
                    style={{ paddingRight: 10 }}>
                    $99DPF Support
                  </span>
                  <span onClick={logOut} className={styles.userMenuItem}>
                    Log out
                  </span>
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
      <Modal open={thanksModal} onClose={() => setThanksModal(false)}>
        <div className={GlobalStyles.modal}>
          <CheckCircleIcon style={{ color: "green", fontSize: "64px" }} />
          <span className={styles.mainTitle}>We have received your email</span>
          <span className={styles.subTitle}>
            Our agent will contact you shortly on your provided email address
          </span>
        </div>
      </Modal>
    </>
  );
};

const localStyles = makeStyles((theme) => {
  return {
    container: {
      height: 80,
      background: colors.top_red_section,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "40px",
      fontWeight: normal,
      boxShadow: "0px 3px 6px #00000029",
      position: "sticky",
      top: 0,
      zIndex: 5,
      [theme.breakpoints.down("md")]: {
        paddingRight: "20px",
      },
    },
    mainTitle: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    subTitle: {
      color: "gray",
      fontWeight: "bold",
      fontSize: "14px",
      marginTop: "5px",
      textAlign: "center",
    },
    imgDiv: {
      width: "130px",
      height: "130px",
      marginBottom: "5px",
      objectFit: "cover",
    },
    noNotification: {
      marginTop: "35%",
      textAlign: "center",
    },
    leftBlock: {
      display: "flex",
      alignItems: "center",
    },

    rightBlock: {
      display: "flex",
      alignItems: "center",
    },
    logo: {
      width: 100,
      cursor: "pointer",
      margin: "40px 40px 0",
      [theme.breakpoints.down("md")]: {
        margin: "5px 15px 0",
        width: 60,
      },
    },
    notificationFooter: {
      display: "flex",
      justifyContent: "space-between",
      top: "295px",
      position: "sticky",
    },
    circularLoader: {
      color: colors.top_red_section,
      marginLeft: "45%",
      marginTop: "30%",
    },
    orders: {
      fontSize: 20,
      lineHeight: "30px",
      fontWeight: semiBold,
      color: colors.text_orders,
      cursor: "pointer",
    },
    focus: {
      fontSize: 20,
      lineHeight: "30px",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer",
      textDecoration: "underline",
      textUnderlineOffset: "10px",
    },
    help: {
      width: 45,
      cursor: "pointer",
      marginRight: 40,
    },
    notificationsIcon: {
      marginRight: 40,
      cursor: "pointer",
    },

    userInfo: {
      cursor: "pointer",
      width: "auto",
      color: colors.theme_fg,
      display: "flex",
      alignItems: "center",
    },
    info: {
      paddingLeft: 10,
      display: "flex",
      flexDirection: "column",
    },
    name: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontSize: 20,
      lineHeight: "20px",
      width: "auto",
    },
    userMenu: {
      width: 195,
      height: 120,
      // padding: "0 5px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      fontSize: 20,
      lineHeight: "28px",
      "& span": {
        cursor: "pointer",
      },
    },
    userMenuItem: {
      paddingLeft: "10px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    active: {
      transform: "rotate(180deg)",
    },
    badgeSize: {
      fontSize: "12px !important",
      height: "18px !important",
      width: "18px !important",
      minWidth: "18px !important",
      top: "7px !important",
      right: "2px !important",
      backgroundColor: "black",
      color: "white",
    },
    notifications: {
      background: colors.theme_fg,
      width: "450px",
      boxSizing: "unset",
      height: "295px",
    },
    clearNotification: {
      textAlign: "center",
      position: "sticky",
      top: "295px",
      width: "50%",
      backgroundColor: "white",
      paddingTop: "10px",
      paddingBottom: "10px",
      cursor: "pointer",
      marginBlockStart: "0rem",
      marginBlockEnd: "0rem",
    },
    notificationPaper: {
      "& .MuiPopover-paper": {
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "white",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: colors.icon_color,
          borderRadius: "5px",
        },
      },
      "& .MuiPaper-elevation": {
        marginLeft: "-140px",
      },
    },
    notification: {
      padding: "10px",
      display: "flex",
      "&& .MuiSvgIcon-root": {
        color: colors.icon_color,
        height: "40px",
        width: "40px",
        background: colors.bg_notification,
        borderRadius: "5px",
        boxShadow: "0px 3px 6px #00000029",
      },
    },
    notificationInfo: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      marginLeft: "10px",
    },
    notificationName: {
      fontSize: "14px",
      fontWeight: "bold",
      color: colors.theme_fg_two,
    },
    notificationDescription: {
      fontSize: "12px",
      color: colors.notification_desc,
    },
    cart: {
      display: "flex",
      alignItems: "center",
      marginRight: "40px",
      marginBottom: "5px",
      color: colors.theme_fg,
      cursor: "pointer",
      "& span": {
        fontSize: "20px",
        marginRight: "8px",
        marginTop: "4px",
      },
      [theme.breakpoints.down("md")]: {
        marginRight: "20px",
      },
    },
  };
});

export default Header;
