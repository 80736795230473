/** @format */

import * as ActionTypes from "./ActionTypes";

export const serviceActionPending = (categoryId, categoryNdxValue) => ({
  type: ActionTypes.PRODUCT_LIST_PENDING,
  categoryId,
  categoryNdxValue,
});

export const serviceActionError = (error) => ({
  type: ActionTypes.PRODUCT_LIST_ERROR,
  error,
});

export const serviceActionSuccess = (data, categoryName) => ({
  type: ActionTypes.PRODUCT_LIST_SUCCESS,
  data,
  categoryName,
});

export const addToCart = (amount, data) => ({
  type: ActionTypes.ADD_TO_CART,
  amount,
  data,
});

export const productReset = () => ({
  type: ActionTypes.PRODUCT_RESET,
});

export const productListReset = () => ({
  type: ActionTypes.PRODUCT_LIST_RESET,
});

export const getCategoryList = () => ({
  type: ActionTypes.GET_CATEGORY_LIST,
});
export const getCategoryListSuccess = (categoryList) => ({
  type: ActionTypes.GET_CATEGORY_LIST_SUCCESS,
  categoryList,
});
export const getCategoryListError = (error) => ({
  type: ActionTypes.GET_CATEGORY_LIST_ERROR,
  error,
});

export const setOnChangeTab = (isLocked, tabPage) => ({
  type: ActionTypes.SET_ON_CHANGE_TAB,
  isLocked,
  tabPage,
});

export const selectedProductList = (productList) => ({
  type: ActionTypes.SELECTED_PRODUCT_LIST,
  productList,
});

export const selectedAddOnProductList = (addOnProductList) => ({
  type: ActionTypes.SELECTED_ADD_ON_PRODUCT_LIST,
  addOnProductList,
});

export const isProductImageLoading = (imageLoading) => ({
  type: ActionTypes.IS_PRODUCT_IMAGE_LOADING,
  imageLoading,
});
export const getCategoryId = (categoryId) => ({
  type: ActionTypes.GET_CATEGORY_ID,
  categoryId,
});
export const emptyProductList = () => ({
  type: ActionTypes.EMPTY_PRODUCT_LIST,
});

export const updateProductSelection = (productId) => ({
  type: ActionTypes.UPDATE_PRODUCT_SELECTION,
  productId,
});
