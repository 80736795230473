/** @format */

import * as ActionTypes from "./ActionTypes";

export const getServiceByParentId = (parent_id) => ({
  type: ActionTypes.GET_SERVICE_BY_PARENT_ID,
  parent_id,
});

export const getServiceByParentIdSuccess = (data) => ({
  type: ActionTypes.GET_SERVICE_BY_PARENT_ID_SUCCESS,
  data: data,
});

export const getServiceByParentIdError = (error) => ({
  type: ActionTypes.GET_SERVICE_BY_PARENT_ID_ERROR,
  error: error,
});
export const getServiceId = (serviceId) => ({
  type: ActionTypes.GET_SERVICE_ID,
  serviceId,
});

export const getProductServiceId = (productServiceId) => ({
  type: ActionTypes.PRODUCT_SERVICE_ID,
  productServiceId,
});

export const getParentService = (parentServiceData) => ({
  type: ActionTypes.GET_PARENT_SERVICE_DATA,
  parentServiceData,
});

export const toggleCartDeleteModal = () => ({
  type: ActionTypes.TOGGLE_CART_DELETE_MODAL,
});

export const resetService = () => ({
  type: ActionTypes.RESER_SERVICES,
});

export const requestServiceAreaPending = () => ({
  type: ActionTypes.REQUEST_SERVICE_AREA_PENDING,
});

export const requestServiceAreaSuccess = (data) => ({
  type: ActionTypes.REQUEST_SERVICE_AREA_SUCCESS,
  data: data,
});

export const requestServiceAreaError = (error) => ({
  type: ActionTypes.REQUEST_SERVICE_AREA_ERROR,
  error: error,
});
