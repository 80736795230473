/** @format */

import * as Actions from "../actions/ActionTypes";
const CheckoutReducer = (
  state = {
    isLoading: false,
    error: undefined,
    paymentMethod: [],
    paymentModal: false,
    paymentModalDetails: null,
    methodNdx: undefined,
    confirmPaymentMethod: null,
    orderLoader: false,
    cardDetails: null,
    stripeToken: null,
    errorMessage: null,
    walletAmount: 0,
    lastScreen: null,
    isDefaultBillingAddress: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.GET_PAYMENT_METHOD:
      return Object.assign({}, state, {
        isLoading: true,
        paymentMethod: [],
        error: undefined,
        confirmPaymentMethod: null,
      });
    case Actions.GET_PAYMENT_METHOD_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        paymentMethod: action.paymentMethod,
        error: undefined,
      });
    case Actions.GET_PAYMENT_METHOD_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        paymentMethod: [],
        error: action.error,
      });
    case Actions.TOGGLE_PAYMENT_MODAL:
      return Object.assign({}, state, {
        paymentModal: !state.paymentModal,
      });
    case Actions.SET_PAYMENT_MODAL_DETAILS:
      return Object.assign({}, state, {
        paymentModalDetails: action.modalDetails,
        methodNdx: action.methodNdx,
      });
    case Actions.GET_CONFIRM_PAYMENT:
      return Object.assign({}, state, {
        confirmPaymentMethod: action.confirmPaymentMethod,
      });
    case Actions.SUBMIT_ORDER:
      return Object.assign({}, state, {
        orderLoader: true,
      });
    case Actions.SUBMIT_ORDER_SUCCESS:
      return Object.assign({}, state, {
        orderLoader: false,
      });
    case Actions.SUBMIT_ORDER_ERROR:
      return Object.assign({}, state, {
        orderLoader: false,
      });
    case Actions.GET_CARD_DETAILS:
      return Object.assign({}, state, {
        cardDetails: action.cardDetails,
      });
    case Actions.GET_STRIPE_TOKEN:
      return Object.assign({}, state, {
        stripeToken: action.stripeToken,
      });
    case Actions.SET_CARD_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage,
      });
    case Actions.GET_WALLET_AMOUNT:
      return Object.assign({}, state, {
        isLoading: true,
        walletAmount: 0,
      });
    case Actions.GET_WALLET_AMOUNT_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        walletAmount: action.data.amount,
        isWalletActive: action.data.is_wallet_active,
      });
    }
    case Actions.GET_WALLET_AMOUNT_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        walletAmount: 0,
      });
    case Actions.GET_LAST_SCREEN:
      return Object.assign({}, state, {
        lastScreen: action.lastScreen,
      });
    case Actions.NO_DEFAULT_BILLING_ADDRESS:
      return Object.assign({}, state, {
        isDefaultBillingAddress: action.isDefaultBillingAddress,
      });
    default:
      return state;
  }
};

export default CheckoutReducer;
