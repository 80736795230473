/** @format */

import { makeStyles } from "@mui/styles";
import * as colors from "../../src/assets/css/Colors";

const localStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ededed",
    },
    header_section: {
      width: "100%",
      height: 397,
      backgroundColor: colors.top_red_section,
      alignItems: "center",
    },
    logo_content: {
      top: 60,
    },
    logo: {
      width: 30,
      height: 25,
    },
    register_name: {
      color: colors.top_red_section,
      fontSize: 20,
      fontWeight: "bold",
      alignSelf: "center",
      marginTop: 10,
    },
    body_section: {
      width: "100%",
      backgroundColor: colors.theme_bg_three,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginTop: "25px",
    },
    inputs: {
      width: "100%",
      padding: "15px 40px 20px",
      boxSizing: "border-box",
      [theme.breakpoints.down("md")]: {
        padding: "15px 20px 20px",
      },
    },
    input: {
      caretColor: colors.icon_color,
      "&.MuiTextField-root": {
        marginBottom: 30,
      },
      "& .MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-20px",
      },
    },
    input_text: {
      borderColor: colors.theme_bg,
      padding: 10,
      borderRadius: 5,
    },
    footer_section: {
      width: "100%",
      alignItems: "center",
    },
    login_content: {
      width: "100%",
      margin: 5,
      alignItems: "center",
    },
    login_string: {
      color: colors.theme_fg,
    },
    btn_style: {
      backgroundColor: colors.theme_bg,
    },
    bottom_section: {
      [theme.breakpoints.down("md")]: {
        margin: "0 20px",
      },
    },
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "500px",
      margin: "-100px auto 0",
      background: colors.theme_fg,
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "5px",
    },
    email: {
      borderColor: colors.theme_bg,

      padding: 10,
      borderRadius: 5,
      height: 40,
    },
    forgot_password_container: {
      width: "100%",
      textAlign: "end",
      fontSize: 14,
      marginTop: 25,
      marginBottom: 25,
      color: colors.description_text_color,
      cursor: "pointer",
    },
    signup_container: {
      display: "flex",
      justifyContent: "center",
      marginTop: 30,
      paddingBottom: 30,
      color: colors.top_red_section,
      cursor: "pointer",
    },
    error_test: {
      alignSelf: "center",
    },
    verification: {
      marginTop: "50px",
      textAlign: "center",
      fontWeight: "bold",
      marginBottom: "10px",
    },
  };
});

export default localStyles;
