/** @format */

import * as ActionTypes from "./ActionTypes";

export const serviceActionPending = () => ({
  type: ActionTypes.CREATE_ADDRESS_PENDING,
});

export const serviceActionError = (error) => ({
  type: ActionTypes.CREATE_ADDRESS_ERROR,
  error: error,
});

export const serviceActionSuccess = (data) => ({
  type: ActionTypes.CREATE_ADDRESS_SUCCESS,
  data: data,
});

export const resetAddressError = () => ({
  type: ActionTypes.RESET_ADDRESS_ERROR,
});

export const setAddressDetailGlobal = (data) => ({
  type: ActionTypes.ADDRESS_DETAIL_GLOBAL,
  data,
});

export const editServiceActionPending = () => ({
  type: ActionTypes.EDIT_ADDRESS_PENDING,
});

export const editServiceActionError = (error) => ({
  type: ActionTypes.EDIT_ADDRESS_ERROR,
  error: error,
});

export const editServiceActionSuccess = (data) => ({
  type: ActionTypes.EDIT_ADDRESS_SUCCESS,
  data: data,
});

export const updateServiceActionPending = () => ({
  type: ActionTypes.UPDATE_ADDRESS_PENDING,
});

export const updateServiceActionError = (error) => ({
  type: ActionTypes.UPDATE_ADDRESS_ERROR,
  error: error,
});

export const updateServiceActionSuccess = (data) => ({
  type: ActionTypes.UPDATE_ADDRESS_SUCCESS,
  data: data,
});

export const getAddressValue = (value) => ({
  type: ActionTypes.GET_ADDRESS_VALUES,
  value,
});

export const discardAddressId = () => ({
  type: ActionTypes.DISCARD_ADDRESS_ID,
});

export const getAddressId = (addressId) => ({
  type: ActionTypes.GET_ADDRESS_ID,
  addressId,
});

export const getAddressIdSuccess = (addressData) => ({
  type: ActionTypes.GET_ADDRESS_ID_SUCCESS,
  addressData,
});

export const getAddressIdError = (error) => ({
  type: ActionTypes.GET_ADDRESS_ID_ERROR,
  error,
});

export const setShowAdditionalDetails = (isShow) => ({
  type: ActionTypes.SHOW_ADDITIONALDETAILS,
  isShow,
});

export const setErrorMessage = (errorMessage) => ({
  type: ActionTypes.SET_ERROR_MESSAGE,
  errorMessage,
});

export const getBillingAddress = () => ({
  type: ActionTypes.GET_BILLING_ADDRESS,
});
export const getBillingAddressSuccess = (billingAddress) => ({
  type: ActionTypes.GET_BILLING_ADDRESS_SUCCESS,
  billingAddress,
});
export const getBillingAddressError = (error) => ({
  type: ActionTypes.GET_BILLING_ADDRESS_ERROR,
  error,
});

export const clearAddressValue = () => ({
  type: ActionTypes.CLEAR_ADDRESS_VALUE,
});
