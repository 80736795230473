/** @format */

import * as Actions from "../actions/ActionTypes";
const HomeReducer = (
  state = {
    isLoading: false,
    error: undefined,
    data: [],
    activeOrder: [],
    message: undefined,
    status: undefined,
    mainParentId: undefined,
    orderError: undefined,
    activeOrderLoading: false,
    msgSeenOfOrderId: undefined,
    completeOrderCount: 0,
    pendingOrderCount: 0,
    todaysOrderCount: 0,
    totalOrderCount: 0,
  },
  action
) => {
  switch (action.type) {
    case Actions.HOME_SERVICE_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        data: [],
      });
    case Actions.HOME_SERVICE_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        data: [],
      });
    case Actions.HOME_SERVICE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        data: action.data,
      });

    case Actions.HOME_ACTIVE_ORDERS_PENDING:
      return Object.assign({}, state, {
        activeOrderLoading: true,
        activeOrder: action.offset === 0 ? [] : [...state.activeOrder],
        orderError: undefined,
        activeOrderCount: action.offset === 0 ? 0 : state.activeOrderCount,
      });
    case Actions.HOME_ACTIVE_ORDERS_SUCCESS: {
      let order = [...state.activeOrder];
      order = order.concat(action.data.getOrder);
      return Object.assign({}, state, {
        activeOrderLoading: false,
        activeOrder: order,
        orderError: undefined,
        activeOrderCount: action.data.count,
      });
    }
    case Actions.HOME_ACTIVE_ORDERS_ERROR:
      return Object.assign({}, state, {
        activeOrderLoading: false,
        orderError: action.error,
        activeOrder: [],
      });
    case Actions.MAIN_PARENT_ID:
      return Object.assign({}, state, {
        mainParentId: action.mainParentId,
      });
    case Actions.SET_TAB_VALUE:
      return Object.assign({}, state, {
        tabValue: action.data,
      });

    case Actions.RESET_TAB_VALUE:
      return Object.assign({}, state, {
        tabValue: undefined,
      });
    case Actions.UPDATE_UNSEEN_MSG_COUNT_COMPLETED: {
      // check in active orders
      const activeOrderIndex = state?.activeOrder?.findIndex(
        (order) => order.order_id === action.data
      );
      if (state.activeOrder.length > 0 && activeOrderIndex != -1)
        state.activeOrder[activeOrderIndex].unseen_message_count = 0;

      // check in completed orders

      return Object.assign({}, state, {
        activeOrder: state.activeOrder,
      });
    }
    case Actions.UPDATE_MSG_COUNT_COMPLETED: {
      // check in completed orders
      const activeOrderIndex = state?.activeOrder?.findIndex(
        (order) => order.order_id === action.data.order_id
      );
      if (state.activeOrder.length > 0 && activeOrderIndex != -1)
        state.activeOrder[activeOrderIndex].unseen_message_count =
          action.data.count;

      return Object.assign({}, state, {
        activeOrder: state.activeOrder,
      });
    }
    case Actions.ORDER_COUNT_SUCCESS:
      return Object.assign({}, state, {
        completeOrderCount: action.data.completeOrderCount,
        pendingOrderCount: action.data.pendingOrderCount,
        todaysOrderCount: action.data.todaysOrderCount,
        totalOrderCount: action.data.totalOrderCount,
      });

    case Actions.ORDER_COUNT_ERROR:
      return Object.assign({}, state, {
        completeOrderCount: 0,
        pendingOrderCount: 0,
        todaysOrderCount: 0,
        totalOrderCount: 0,
      });

    default:
      return state;
  }
};

export default HomeReducer;
